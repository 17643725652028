import React, { useState, useEffect, useLayoutEffect, useCallback } from "react";
import MetaTags from "react-meta-tags";
import axios from "axios";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getCookie } from "../../common/utils/Cookie";
import { getUserAPI, blockListAPI } from "common/api/user";

import { Container, Row, Col, Button, Card, CardBody, Input, Table, CardTitle, CardSubtitle } from "reactstrap";

function BlockUser() {
  const queryClient = useQueryClient();
  // const [blockLists, setBlockLists] = useState([]);

  const { data: userInfo } = useQuery("users", getUserAPI);
  const USER_CODE = userInfo?.USER_CODE;
  const COMPANY_CODE = userInfo?.COMPANY_CODE;
  const STATUS = "R";
  const { data: blockLists } = useQuery(
    ["users", USER_CODE, COMPANY_CODE, STATUS],
    () => blockListAPI({ USER_CODE: USER_CODE, COMPANY_CODE: COMPANY_CODE, STATUS: STATUS }),
    {
      enabled: !!USER_CODE,
    },
  );
  console.log("blockLists --- ", blockLists);

  // 유저 상태 수정
  const updateData = useMutation({
    mutationFn: USER_CODE =>
      axios.post(
        process.env.REACT_APP_APIKEY + `/api/users/userrefusupdate`,
        { USER_CODE: USER_CODE, STATUS: selected },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        console.log("USER_CODE ------ ", USER_CODE),
      ),
    onSuccess: () => {
      // 요청이 성공한 경우
      console.log("onSuccess");
      queryClient.invalidateQueries("users");
    },
    onError: error => {
      alert("유저 수정에 실패하였습니다.");
    },
  });
  // 유저 상태 수정 버튼
  const updateBtn = USER_CODE => {
    updateData.mutate(USER_CODE); // 데이터 저장
  };

  // const updateBtn = useMutation(USER_CODE => {
  //   axios
  //     .post(
  //       process.env.REACT_APP_APIKEY + `/api/users/userrefusupdate`,
  //       { USER_CODE: USER_CODE, STATUS: selected },
  //       {
  //         headers: {
  //           Authorization: getCookie("xToken"),
  //           "Content-Type": "application/json;charset=UTF-8",
  //         },
  //       },
  //     )
  //     .then(res => {
  //       if (res.data.result === "success") {
  //         console.log("수정", res);
  //         alert("수정 성공");
  //         // dataLists();
  //       } else if (res.data.result === "fail") {
  //         console.log("실패", res);
  //         alert("수정 실패");
  //         return false;
  //       }
  //     });
  // });

  const [selected, setSelected] = useState("");
  let optionGroup = [
    { label: "차단", value: "R" },
    { label: "허용", value: "A" },
  ];
  var inquiries = optionGroup.filter(data => {
    if (selected === "R" && data.label.toLowerCase() === "R") {
      console.log("1", data);
      return data;
    } else if (selected === "A" && data.label.toLowerCase() === "A") {
      console.log("2", data);
      return data;
    }
  });

  const changeSelectOptionHandler = e => {
    setSelected(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>SHUKET | Block User List</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="UI Elements" breadcrumbItem="차단 사용자 리스트" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle>차단된 사용자</CardTitle>
                  <CardSubtitle className="mb-3">차단된 유저 리스트의 정보를 변경할 수 있습니다.</CardSubtitle>

                  <div>
                    <Table className="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>이름</th>
                          <th>이메일</th>
                          <th>전화번호</th>
                          <th>직함</th>
                          <th>차단여부</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {blockLists ? (
                          blockLists.map((blockList, index) => {
                            return (
                              <tr key={blockList.USER_CODE}>
                                <th scope="row">{index + 1}</th>
                                <td>{blockList.USER_NAME}</td>
                                <td>{blockList.USER_EMAIL}</td>
                                <td>{blockList.PHONE_NUM}</td>
                                <td>{blockList.USER_POSITION}</td>
                                <td>
                                  <Input
                                    className="form-control"
                                    type="select"
                                    name={blockList.USER_CODE}
                                    id={blockList.USER_CODE}
                                    defaultValue={blockList.STATUS}
                                    value={inquiries.value}
                                    onChange={e => {
                                      changeSelectOptionHandler(e);
                                      console.log(inquiries.values, blockList.USER_CODE);
                                    }}
                                  >
                                    <option name="R" value="R">
                                      차단
                                    </option>
                                    <option name="A" value="A">
                                      허용
                                    </option>
                                  </Input>
                                </td>
                                <td>
                                  <Button onClick={() => updateBtn(blockList.USER_CODE)}>수정</Button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <th>차단된 사용자가 없습니다.</th>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {/* {isLoading ? <Spinner className="ms-2" color="warning" style={{ width: "50px", height: "50px", margin: "40px 50%" }} /> : ""} */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default BlockUser;
