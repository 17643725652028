import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Card, Form, CardBody, Col, Row, CardTitle, Container, Label, Input, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
import "rc-pagination/assets/index.css";
import Pagination from "rc-pagination";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useQuery } from "react-query";
import { setCookie, getCookie, removeCookie } from "../../common/utils/Cookie";
import { userAPI } from "../../common/api/user";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
function QnaBoard(props) {
  // const [loading, setLoading] = useState(false);
  // const [userData, setUserData] = useState({});
  const [boardList, setBoardList] = useState([]);
  const [textcount, settextcount] = useState(0);

  const [titlecount, setTitletextcount] = useState(0);
  const [boardTotalCount, setBoardTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(props.match.params.pageno * 1);
  // const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  // const [pageInfo, setPageInfo] = useState("");
  const [textareabadgeTitle, settextareabadgeTitle] = useState(0);
  const [textareabadge, settextareabadge] = useState(0);
  const [titleText, setTitleText] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [createPostModal, setCreatePostModal] = useState(false);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = boardList != null ? boardList.slice(indexOfFirstPost, indexOfLastPost) : [];
  const history = useHistory();

  let myUserCode = getCookie("userCode");

  const userCode = getCookie("userCode") || location.state.userCode;
  const { data: userData } = useQuery(["users", userCode], () => userAPI(userCode));
  function textareachange(event) {
    const count = event.target.value.length;
    setDescriptionText(event.target.value);
    // console.log(descriptionText);
    if (count > 0) {
      settextareabadge(true);
    } else {
      settextareabadge(false);
    }
    settextcount(event.target.value.length);
  }

  function titletextareachange(event) {
    const count = event.target.value.length;
    setTitleText(event.target.value);
    // console.log(descriptionText);
    if (count > 0) {
      settextareabadgeTitle(true);
    } else {
      settextareabadgeTitle(false);
    }
    setTitletextcount(event.target.value.length);
  }

  const toggleCreatePostModal = () => {
    setCreatePostModal(!createPostModal);
  };

  const onClickHandler = e => {
    let questionCode = e.target.getAttribute("data-arg1");
    console.log(questionCode);
    axios
      .post(process.env.REACT_APP_APIKEY + "/api/qna/get", {
        QNA_CODE: questionCode,
      })
      .then(async res => {
        if (res.data.data != null) {
          history.push({
            pathname: "/qnadetail",
            state: { questionCode: questionCode, userData: userData },
          });
        }
      });
  };

  const modalBtnOnClick = async () => {
    toggleCreatePostModal();
  };

  const createQnaPost = async () => {
    axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/qna/create",
        {
          USER_CODE: userData?.USER_CODE,
          TITLE: titleText,
          CONTENT: descriptionText,
          USER_NAME: userData?.USER_NAME,
          GROUP_CODE: userData?.USER_CODE,
        },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
          responseType: "json",
        },
      )
      .then(async res => {
        if (res.data.data != null) {
          history.push("/qnaboard/1");
          toggleCreatePostModal();
          setTitleText("");
          setDescriptionText("");
          listBoard();
        }
      });
  };
  const listBoard = async () => {
    // if ((myUserCode = "UR00000002")) {
    //   // 관리자 코드일때 리스팅
    //   // 모든 유저가 쓴 글 이 보여야한다. 전체 조회.

    // } else {
    //   // 나머지 유저일때 리스팅
    //   // 내가 쓴 글과, 내글에 답변달은 관리자의 글만 보여야한다.
    // }
    axios
      .get(process.env.REACT_APP_APIKEY + "/api/qna/list/" + props.match.params.pageno + "?uc=" + myUserCode, {
        headers: {
          Authorization: getCookie("xToken"),
          "Content-Type": "application/json;charset=UTF-8",
        },
        responseType: "json",
      })
      .then(async res => {
        if (res.data.data != null) {
          setBoardList(res.data.data.rows);
          setBoardTotalCount(res.data.data.count);
        }
      });
  };

  useEffect(() => {
    listBoard();
    if (!getCookie("xToken")) {
      history.push("/");
    }
  }, [currentPage]);

  const btnStyle = {
    margin: "10px",
  };
  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={createPostModal}
        className="exampleModal"
        toggle={toggleCreatePostModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleCreatePostModal}>1:1 문의 글 작성</ModalHeader>
          <ModalBody>
            <div className="mt-3">
              <Label>제목</Label>
              <Input
                value={titleText}
                maxLength="50"
                rows="3"
                onChange={e => {
                  titletextareachange(e);
                }}
                placeholder="입력해주세요."
              />
              {textareabadgeTitle ? <span className="badgecount badge bg-success"> {titlecount} / 50 </span> : null}
            </div>
            <div className="mt-3">
              <Label>문의 내용</Label>
              <Form method="post">
                <CKEditor
                  editor={ClassicEditor}
                  data=""
                  editorConfig={{
                    allowedContent: true,
                    extraAllowedContent: "style",
                  }}
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescriptionText(data);
                  }}
                  config={{
                    autoParagraph: false,
                    enterMode: "br",
                    ShiftEnterMode: "br",
                  }}
                />
              </Form>
              {/* <Input
                type="textarea"
                id="textarea"
                onChange={e => {
                  textareachange(e);
                }}
                value={descriptionText}
                maxLength="5000"
                rows="3"
                placeholder="입력해주세요."
              />
              {textareabadge ? <span className="badgecount badge bg-success"> {textcount} / 5000 </span> : null} */}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={createQnaPost}>
              글쓰기
            </Button>
            <Button type="button" color="secondary" onClick={toggleCreatePostModal}>
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <div className="page-content">
        <MetaTags>
          <title>OPS | 1:1 QnA</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Q&A Board" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">QnA 게시판</CardTitle>
                  <div align="right">
                    <Button className="bg-secondary" style={btnStyle} onClick={modalBtnOnClick}>
                      글 쓰기
                    </Button>
                  </div>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="table-light">
                        <tr>
                          <th>#</th>
                          <th>제목</th>
                          <th>작성자</th>
                          <th>작성일</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPosts
                          ? boardList.map(data => {
                              return (
                                <tr key={data.QNA_CODE}>
                                  <td scope="row">{data.row_num}</td>
                                  <td data-arg1={data.QNA_CODE} style={{ cursor: "pointer" }} onClick={onClickHandler}>
                                    {data.DEPTH > 0 ? `RE : ` + data.TITLE : data.TITLE}
                                  </td>
                                  <td>{data.USER_NAME}</td>

                                  <td>{moment(data.C_TIME).format("YYYY-MM-DD")}</td>
                                </tr>
                              );
                            })
                          : "1:1 문의 내역이 존재하지 않습니다."}
                      </tbody>
                    </Table>
                  </div>
                  {boardTotalCount && boardTotalCount ? (
                    <Pagination
                      className="ant-pagination d-flex justify-content-center"
                      total={boardTotalCount}
                      current={currentPage}
                      pageSize={postsPerPage}
                      onChange={page => {
                        setCurrentPage(page);
                        history.push(`/qnaboard/${page}`);
                      }}
                    />
                  ) : (
                    <div></div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
export default QnaBoard;

QnaBoard.propTypes = {
  props: PropTypes.object,
  match: PropTypes.object,
};
