import React, { useEffect, useState } from "react";
import { Container, Row, Col, Input, Button, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Table, CardTitle } from "reactstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getCookie } from "../../common/utils/Cookie";
import "rc-pagination/assets/index.css";
import Pagination from "rc-pagination";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

function MyBlock(props) {
  const btnStyle = {
    margin: "10px",
  };
  const imgStyle = {
    position: "relative",
    height: "200px",
    width: "200px",
  };
  const [blockNameClickLink, setBlockNameClickLink] = useState("");
  const [modalMoreBlock, setModalMoreBlock] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [blockSnapShot, setBlockSnapShot] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const [selectedBlock, setSelectedBlock] = useState("L");
  const toggleModalDetail = () => setModalDetail(!modalDetail);

  const blockNameClick = e => {
    toggleModalDetail();
    let snapShotCode = e.target.getAttribute("data-arg");
    let snapShotJson = JSON.parse(snapShotCode);
    console.log(snapShotJson);
    setBlockSnapShot(snapShotJson[0]);
    let blockNameClickLink = e.target.getAttribute("data-arg3");
    console.log(blockNameClickLink);
    setBlockNameClickLink(blockNameClickLink);
  };

  const toggleBlockLists = () => setModalMoreBlock(!modalMoreBlock);
  const viewMoreBlock = () => {
    toggleBlockLists();
  };

  const linkEditorBlockNameBtn = async e => {
    let userCode = props.userData.USER_CODE;
    let companyCode = props.userData.COMPANY_CODE;
    // form 만들어서 보내기
    const frm = document.createElement("form");
    frm.setAttribute("id", "auth-form");
    frm.setAttribute("action", process.env.REACT_APP_EDITOR_URL + "/workspace");
    frm.setAttribute("method", "post");
    frm.setAttribute("target", "auth-form");
    let input1 = document.createElement("input");
    let input2 = document.createElement("input");
    let input3 = document.createElement("input");
    let input4 = document.createElement("input");
    input1.name = "rc";
    input1.value = blockNameClickLink;
    frm.appendChild(input1);
    input2.name = "uc";
    input2.value = userCode;
    frm.appendChild(input2);
    input3.name = "cc";
    input3.value = companyCode;
    frm.appendChild(input3);
    input4.name = "token";
    input4.value = getCookie("xToken");
    frm.appendChild(input4);
    frm.enctype = "application/json";
    document.body.appendChild(frm);
    window.open("about:blank", "auth-form");
    frm.submit();
    document.body.removeChild(frm);
    toggleModalDetail();
  };
  const firstRadio = e => {
    props.setBlockPublic(e.target.value);
    setSelectedBlock(e.target.value);
  };
  const secondRadio = e => {
    props.setBlockPublic(e.target.value);
    setSelectedBlock(e.target.value);
  };
  useEffect(() => {
    props.getBlockInfo();
    props.listBlock();
  }, [selectedBlock]);
  const history = useHistory();

  const imagestyle = {
    overflow: "auto",
  };
  useEffect(() => {
    if (!getCookie("xToken")) {
      history.pushState("/");
    }
  }, []);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = props.blockList != null ? props.blockList.slice(indexOfFirstPost, indexOfLastPost) : [];

  return (
    <>
      <CardBody>
        <Row>
          <Col>
            <CardTitle className="mb-2">{props.t("My Block")}</CardTitle>
            <div className="Container-layout test-box">
              <div className="form-check col-md-3 box-layout">
                <Input className="form-check-input" type="radio" name="blockRadios" id="blockRadios1" value="L" onChange={firstRadio} defaultChecked />
                <label className="form-check-label" htmlFor="blockRadios1">
                  {props.t("Local")}
                </label>
              </div>

              <div className="form-check col-md-3 box-layout">
                <Input className="form-check-input" type="radio" name="blockRadios" id="blockRadios2" value="G" onChange={secondRadio} />
                <label className="form-check-labe2" htmlFor="blockRadios2">
                  {props.t("Global")}
                </label>
              </div>
            </div>
          </Col>
        </Row>
        <div className="table-responsive">
          <Table className="table mb-0">
            <thead className="table-light">
              <tr>
                <th>#</th>
                <th>{props.t("Block Name")}</th>
                <th>{props.t("Create Date")}</th>
                <th>{props.t("Usage history")}</th>
              </tr>
            </thead>
            <tbody>
              {props.blockInfo != null ? (
                props.blockInfo.map((data, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        {data.TEMPLATE_NAME}
                        <Button
                          className="bx btn-primary btn-sm btn-rounded bx-link-external"
                          color="primary"
                          style={btnStyle}
                          onClick={blockNameClick}
                          data-arg={data.TEMPLATE_SNAPSHOT}
                          data-arg3={data.TEMPLATE_CODE}
                        ></Button>
                      </td>
                      <td>{moment(data.M_TIME).format("YYYY-MM-DD")}</td>
                      <td>{data.TEMPLATE_USE_COUNT} 건</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>데이터가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </CardBody>
      <Button color="primary" onClick={viewMoreBlock}>
        {props.t("Show All")}
      </Button>

      {/* 더보기 모달 */}
      <Modal size="xl" isOpen={modalMoreBlock} className="exampleModal" toggle={toggleBlockLists} role="dialog" autoFocus={true} centered={true} tabIndex="-1">
        <div className="modal-content">
          <ModalHeader toggle={toggleBlockLists}>{props.t("Block List")}</ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <Table className="table align-middle table-nowrap">
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th>{props.t("Block Name")}</th>
                    <th>{props.t("Create Date")}</th>
                    <th>{props.t("Usage history")}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPosts ? (
                    currentPosts.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td scope="row">{data.row_num}</td>
                          <td>
                            {data.TEMPLATE_NAME}
                            <Button
                              className="bx btn-primary btn-sm btn-rounded bx-link-external"
                              color="primary"
                              style={btnStyle}
                              onClick={blockNameClick}
                              data-arg={data.TEMPLATE_SNAPSHOT}
                              data-arg3={data.TEMPLATE_CODE}
                            ></Button>
                          </td>
                          <td>{moment(data.M_TIME).format("YYYY-MM-DD")}</td>
                          <td>{data.TEMPLATE_USE_COUNT} 건</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {props.blockCount && props.blockCount ? (
              <Pagination
                className="ant-pagination d-flex justify-content-center"
                total={props.blockCount}
                current={currentPage}
                pageSize={postsPerPage}
                onChange={page => setCurrentPage(page)}
              />
            ) : (
              <div></div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={toggleBlockLists}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>

      {/* 이미지 미리보기 모달 */}
      <Modal size="lg" isOpen={modalDetail} className="exampleModal" toggle={toggleModalDetail} role="dialog" autoFocus={true} centered={true} tabIndex="-1">
        <div className="modal-content">
          <ModalHeader toggle={toggleModalDetail}>{props.t("Block Preview")}</ModalHeader>
          <ModalBody style={imagestyle}>
            <Container>
              <Row>
                <Col xs={12} md={12}>
                  {blockSnapShot ? <img style={imgStyle} src={process.env.REACT_APP_APIKEY + `/${blockSnapShot}`}></img> : ""}
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={linkEditorBlockNameBtn}>
              {props.t("editor")}
            </Button>
            <Button type="button" color="primary" onClick={toggleModalDetail}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
}

// export default MyBlock;
MyBlock.propTypes = {
  blockInfo: PropTypes.any,
  blockList: PropTypes.any,
  blockCount: PropTypes.any,
  listBlock: PropTypes.any,
  getBlockInfo: PropTypes.any,
  setBlockPublic: PropTypes.any,
  userData: PropTypes.any,
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(MyBlock));
