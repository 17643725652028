import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Input } from "reactstrap";
import Select from "react-select";
import axios from "axios";
import DaumPostcode from "react-daum-postcode";
import { ProgressBar } from "react-bootstrap";
import InputMask from "react-input-mask";
import { useHistory, useLocation } from "react-router-dom";

const TabBusiness = props => {
  const history = useHistory();
  const location = useLocation();
  const [company, setCompany] = useState(""); // 회사 이름
  const [regNumber, setRegNumber] = useState(); // 사업자 등록번호
  const [zipNumber, setZipNumber] = useState(""); // 우편번호
  const [address, setAddress] = useState(""); // 주소
  const [addressDetail, setAddressDetail] = useState(""); // 상세주소
  const [regFile, setRegFile] = useState(""); // 사업자등록증
  const [selectedGroup, setSelectedGroup] = useState("AGENCY"); // 업종
  const [isOpenPost, setIsOpenPost] = useState(false);
  const [progress, setProgress] = useState([]);
  const [file, setFile] = useState(null);
  const [companyMessage, setCompanyMessage] = useState(""); // 회사 이름
  const [regNumberMessage, setRegNumberMessage] = useState(); // 사업자 등록번호
  const [zipNumberMessage, setZipNumberMessage] = useState(""); // 우편번호
  // const [addressMessage, setAddressMessage] = useState("") // 주소
  const [addressDetailMessage, setAddressDetailMessage] = useState(""); // 상세주소
  const [regFileileMessage, setRegFileMessage] = useState(); // 사업자등록증

  const [isFile, setIsFile] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [isRegNumber, setIsRegNumber] = useState(false);
  const [isZipNumber, setIsZipNumber] = useState(false);
  // const [isAddress, setIsAddress] = useState(false)
  const [isAddressDetail, setIsAddressDetail] = useState(false);
  const [isRegFile, setIsRegFile] = useState(false);
  const [isSelectedGroup, setIsSelectedGroup] = useState(false);

  let optionGroup = [
    { label: "광고기획", value: "AGENCY" },
    { label: "마트유통", value: "MART" },
    { label: "기타", value: "XX" },
  ];
  const userData = location.state.userData;
  useEffect(() => {
    console.log("USER_EMAIL ? ? : ", userData.userEmail);
  }, []);

  // 회사 인풋 정보
  const companyOnChange = event => {
    setCompany(event.target.value);
    if (event.target.value.length < 2 || event.target.value.length > 20) {
      setCompanyMessage("1글자 이상 20글자 미만으로 입력해주세요.");
      setIsCompany(false);
    } else {
      setCompanyMessage("올바른 이름 형식입니다 :)");
      setIsCompany(true);
    }
  };
  // 사업자 등록번호
  const regNumberOnChange = event => {
    setIsRegNumber(false);
    setRegNumber(event.target.value);
    if (event.target.value.length != 12) {
      setRegNumberMessage("올바른 사업자 등록번호를 입력 해 주세요.");
      setIsRegNumber(false);
    } else {
      setRegNumberMessage("올바른 사업자등록번호 형식입니다 :)");
      setIsRegNumber(true);
    }
  };
  // 파일 업로드 정보
  const handleOnChange = async event => {
    setRegFile(event.target.files[0]);
    console.log(event.target.files[0]);
    if (!event.target.files[0]) {
      setRegFileMessage("사업자 등롣증을 업로드 해주세요.");
      setIsRegFile(false);
      setIsFile(false);
    } else {
      setRegFileMessage("업로드 완료 :)");
      setIsRegFile(true);
      setIsFile(true);
    }
  };

  // 사업자번호 중복체크
  const regNumCheck = async () => {
    const regnum = regNumber;
    const reg_regNum = checkCorporateRegiNumber(regnum);
    console.log(reg_regNum);

    if (reg_regNum) {
      axios
        .post(process.env.REACT_APP_APIKEY + `/api/signup/regnumcheck`, {
          regnumber: regnum,
        })
        .then(response => {
          console.log(response.data);
          if (response.data.success == true) {
            alert("사용 가능합니다.");
            setIsRegNumber(true);
          } else {
            alert("중복된 사업자 등록번호 입니다.");
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      alert("잘못된 사업자 등록번호를 입력하였습니다.");
      return false;
    }
  };

  // 사업자 등록번호 체크 정규식
  function checkCorporateRegiNumber(number) {
    var numberMap = number
      .replace(/-/gi, "")
      .split("")
      .map(function (d) {
        return parseInt(d, 10);
      });

    if (numberMap.length == 10) {
      var keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
      var chk = 0;

      keyArr.forEach(function (d, i) {
        chk += d * numberMap[i];
      });

      chk += parseInt((keyArr[8] * numberMap[8]) / 10, 10);
      console.log(chk);
      return Math.floor(numberMap[9]) === (10 - (chk % 10)) % 10;
    }

    return false;
  }
  // 업종 선택
  const groupOnChange = value => {
    setSelectedGroup(value);
  };

  const handleOnSubmit = async () => {
    if (!isRegFile) {
      alert("파일을 업로드 해주세요.");
      return false;
    }
    const formData = new FormData();
    formData.append("uploadfile", regFile);

    const response = await axios.post(process.env.REACT_APP_APIKEY + `/upload/uploadSingleCompany`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: data => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    });
    const data = response;
    if (data) {
      setRegFile(null);
      setIsRegFile(true);
      alert("업로드가 완료되었습니다.");
    }
  };

  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
  };

  const onCompletePost = data => {
    console.log(data);
    let fullAddr = data.address;
    let extraAddr = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddr += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddr += extraAddr !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== "" ? ` (${extraAddr})` : "";
    }

    setZipNumber(data.zonecode);
    setAddress(fullAddr);
    setIsZipNumber(true);
    setIsOpenPost(false);
    console.log(fullAddr);
  };
  // 나머지 주소
  const addressDetailOnChange = event => {
    setAddressDetail(event.target.value);
    if (event.target.value == undefined) {
      setAddressDetailMessage("상세 주소를 입력해 주세요..");
      setIsAddressDetail(false);
    } else {
      setAddressDetailMessage("입력 완료. :)");
      setIsAddressDetail(true);
    }
  };

  const postCodeStyle = {
    display: "block",
    position: "relative",
    top: "0%",
    width: "400px",
    height: "400px",
    padding: "7px",
  };
  const requiredStyle = {
    color: "red",
    float: "right",
  };

  const nextPageBtn = () => {
    if (!isCompany) {
      alert("회사명을 입력하여 주세요.");
      return false;
    } else if (!isRegNumber) {
      alert("사업자등록번호 중복채크를 해주세요.");
      return false;
    } else if (!isZipNumber) {
      alert("주소를 입력해 주세요.");
      return false;
    } else if (!isAddressDetail) {
      alert("나머지주소를 입력하여 주세요.");
      return false;
    } else if (!isRegFile) {
      alert("사업자등록증을 업로드 해 주세요.");
      return false;
    } else {
      axios
        .post(process.env.REACT_APP_APIKEY + `/api/signup/createCompany`, {
          COMPANY_CODE: "CA",
          COMPANY_NAME: company,
          COMPANY_TYPE: selectedGroup,
          REG_NUMBER: regNumber,
          ZIP_NUMBER: zipNumber,
          ADDRESS: address,
          ADDRESS_DETAIL: addressDetail,
          inviteEmail: userData.userEmail,
        })
        .then(res => {
          history.push({
            pathname: `/loginTab/tabContent`,
            state: { userData: userData },
          });
          alert("회사 등록에 성공하셨습니다.");
        });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Form Layouts | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">사업자 정보 입력</CardTitle>

                  {/* <Form> */}
                  <div className="row mb-4">
                    <Label htmlFor="horizontal-firstname-Input" className="col-sm-3 col-form-label">
                      회사명
                      <em className="required" style={requiredStyle}>
                        필수
                      </em>
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="horizontal-firstname-Input"
                        placeholder="회사명을 입력하세요."
                        onChange={companyOnChange}
                        value={company || ""}
                      />
                      {company.length > 0 && <span className={`message ${isCompany ? "success" : "error"}`}>{companyMessage}</span>}
                    </Col>
                  </div>

                  <div className="row mb-4">
                    <Label htmlFor="horizontal-email-Input" className="col-sm-3 col-form-label">
                      사업자등록번호
                      <em className="required" style={requiredStyle}>
                        필수
                      </em>
                    </Label>
                    <Col sm={7}>
                      <InputMask mask="999-99-99999" value={regNumber || ""} className="form-control input-color" onChange={regNumberOnChange}></InputMask>
                    </Col>
                    <Col sm={2}>
                      <Button color="primary" className="w-md" onClick={regNumCheck}>
                        중복확인
                      </Button>
                    </Col>
                  </div>

                  <div className="row mb-4">
                    <Label htmlFor="horizontal-email-Input" className="col-sm-3 col-form-label">
                      주소
                      <em className="required" style={requiredStyle}>
                        필수
                      </em>
                    </Label>
                    <Col sm={3}>
                      <Input type="text" className="form-control" placeholder="" readOnly value={zipNumber || ""} />
                      {isOpenPost ? <DaumPostcode style={postCodeStyle} autoClose onComplete={onCompletePost} /> : null}
                    </Col>
                    <Col sm={2}>
                      <Button onClick={onChangeOpenPost} color="primary" className="w-md">
                        주소검색
                      </Button>
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="horizontal-email-Input" className="col-sm-3 col-form-label"></Label>
                    <Col sm={9}>
                      <Input type="text" className="form-control" id="horizontal-email-Input" placeholder="" readOnly value={address || ""} />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label className="col-sm-3 col-form-label"></Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="상세주소를 입력하세요."
                        onChange={addressDetailOnChange}
                        value={addressDetail || ""}
                      />
                      {addressDetail.length > 0 && <span className={`message ${isAddressDetail ? "success" : "error"}`}>{addressDetailMessage}</span>}
                    </Col>
                  </div>

                  <div className="row mb-4">
                    <Label htmlFor="formFile" className="col-sm-3 col-form-label">
                      사업자 등록증
                      <em className="required" style={requiredStyle}>
                        필수
                      </em>
                    </Label>
                    <Col sm={7}>
                      <Input className="form-control" type="file" id="formFile" accept=".jpg, .png, .jpeg, .pdf" onChange={handleOnChange} />
                    </Col>
                    <Col sm={2}>
                      <Button color="primary" className="w-md" onClick={handleOnSubmit}>
                        업로드
                      </Button>
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="formFile" className="col-sm-3 col-form-label"></Label>
                    <Col sm={9}>{progress && <ProgressBar now={progress} label={`${progress}%`} />}</Col>
                  </div>
                  <div className="mb-4 row">
                    <Label className="col-md-3 col-form-label">
                      업종
                      <em className="required" style={requiredStyle}>
                        필수
                      </em>
                    </Label>
                    <Col sm={9}>
                      <Select
                        value={optionGroup.find(op => {
                          // choice state에 따라 디폴트 option 세팅
                          return op.value === selectedGroup;
                        })}
                        onChange={selectedGroup => {
                          groupOnChange(selectedGroup.value);
                        }}
                        classNamePrefix="select2-selection"
                        options={optionGroup}
                      />
                    </Col>
                  </div>

                  <div className="row justify-content-end">
                    <Col sm={9}>
                      <div>
                        <Button onClick={nextPageBtn} color="primary" className="w-md">
                          다음
                        </Button>
                      </div>
                    </Col>
                  </div>
                  {/* </Form> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
TabBusiness.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  userData: PropTypes.string,
};

export default TabBusiness;
