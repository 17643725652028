import axios from "axios";
import React, { useState, useEffect } from "react";
import { CardBody, Container, CardTitle, Row, Col, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import "rc-pagination/assets/index.css";
import Pagination from "rc-pagination";
//i18n
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { getCookie } from "common/utils/Cookie";
import HistoryComment from "./HistoryComment/HistoryComment";
import "./index.css";
function InfoSearch(props) {
  // let userCode = props.userData.USER_CODE;
  const btnStyle = {
    margin: "10px",
  };
  const imagestyle = {
    overflow: "auto",
  };
  const imgStyle = {
    position: "relative",
    height: "200px",
    width: "200px",
  };
  const userCode = getCookie("userCode");
  // const userCode = props.userData.USER_CODE;
  const [check, setCheck] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [viewCompanyModal, setViewCompanyModal] = useState(false);
  const [viewMartModal, setViewMartModal] = useState(false);
  const [communicationModal, setCommunicationModal] = useState(false);
  const [templateDetailModal, setTemplateDetailModal] = useState(false);
  const [templateNameClickLink, setTemplateNameClickLink] = useState("");
  const [templateSnapShot, setTemplateSnapShot] = useState("");

  const [blockDetailModal, setBlockDetailModal] = useState(false);
  const [blockNameClickLink, setBlockNameClickLink] = useState("");
  const [blockSnapShot, setBlockSnapShot] = useState("");

  const [searchPDatas, setSearchPDatas] = useState([]);
  const [searchTDatas, setSearchTDatas] = useState([]);
  const [searchPCount, setSearchPCount] = useState([]);
  const [searchTCount, setSearchTCount] = useState([]);

  const [searchProjectDatas, setSearchProjectDatas] = useState();
  const [searchTemplateDatas, setSearchTemplateDatas] = useState();
  const [searchBlockDatas, setSearchBlockDatas] = useState();

  const [modalMore, setModalMore] = useState(false);
  const [modalTemplate, setModalTemplate] = useState(false);
  const [modalBlock, setModalBlock] = useState(false);
  const [modalProject, setModalProject] = useState(false);

  const [templateCount, setTemplateCount] = useState([]);
  const [blockCount, setBlockCount] = useState([]);
  const [projectCount, setProjectCount] = useState([]);
  const [aCompanyCode, setACompanyCode] = useState("");
  const [mCompanyCode, setMCompanyCode] = useState("");
  const [companyNum, setCompanyNum] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyRegNum, setCompanyRegNum] = useState("");
  const [companyZipNum, setCompanyZipNum] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [commentDates, setCommentDates] = useState([]);

  const [postsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [currentPPage, setCurrentPPage] = useState(1);
  const indexOfLastPostP = currentPPage * postsPerPage;
  const indexOfFirstPostP = indexOfLastPostP - postsPerPage;
  const [currentTPage, setCurrentTPage] = useState(1);
  const indexOfLastPostT = currentTPage * postsPerPage;
  const indexOfFirstPostT = indexOfLastPostT - postsPerPage;

  const currentProjectPosts = searchProjectDatas != null ? searchProjectDatas.slice(indexOfFirstPost, indexOfLastPost) : [];
  const currentTemplatePosts = searchTemplateDatas != null ? searchTemplateDatas.slice(indexOfFirstPost, indexOfLastPost) : [];
  const currentBlockPosts = searchBlockDatas != null ? searchBlockDatas.slice(indexOfFirstPost, indexOfLastPost) : [];

  const currentAllProjectPosts = searchPDatas != null ? searchPDatas.slice(indexOfFirstPostP, indexOfLastPostP) : [];
  const currentAllTemplatePosts = searchTDatas != null ? searchTDatas.slice(indexOfFirstPostT, indexOfLastPostT) : [];
  const toggleTemplateSearch = () => setModalTemplate(!modalTemplate);
  const toggleProjectSearch = () => setModalProject(!modalProject);
  const toggleBlockSearch = () => setModalBlock(!modalBlock);
  const toggleLists = () => setModalMore(!modalMore);
  const toggleTamplateDetail = () => setTemplateDetailModal(!templateDetailModal);
  const toggleCommunication = () => {
    // setCompanyNum(PROJECT_CODE);
    console.log(companyNum);
    setCommunicationModal(!communicationModal);
  };
  const toggleBlockDetail = () => setBlockDetailModal(!blockDetailModal);
  const communicationBtn = async e => {
    let projectNumClick = e.target.getAttribute("data-arg4");
    // console.log("프로젝트 코드는 ? ? ? ? ", projectNumClick);
    setCompanyNum(projectNumClick);
    await axios.get(process.env.REACT_APP_APIKEY + `/api/comment/date?PROJECT_CODE=${projectNumClick}`).then(async res => {
      setCommentDates(res.data.data);
    });
    toggleCommunication();
  };

  const toggleViewCompany = () => {
    setViewCompanyModal(!viewCompanyModal);
  };
  const viewCompanyBtn = async e => {
    toggleViewCompany();
    let aCompanyCode = e.target.getAttribute("data-arg1");
    setACompanyCode(aCompanyCode);
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/company/getCompany",
        {
          COMPANY_CODE: aCompanyCode,
        },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
          responseType: "json",
        },
      )
      .then(async res => {
        // console.log(res); // 프로젝트코드 리턴받음.
        setCompanyName(res.data.data.COMPANY_NAME);
        setCompanyRegNum(res.data.data.REG_NUM);
        setCompanyZipNum(res.data.data.ZIP_NUM);
        setCompanyAddress(res.data.data.ADDRESS + res.data.data.ADDRESS_DETAIL);
      });
  };

  const toggleViewMart = () => {
    setViewMartModal(!viewMartModal);
  };
  const viewMartBtn = async e => {
    toggleViewMart();
    let bCompanyCode = e.target.getAttribute("data-arg2");
    setMCompanyCode(bCompanyCode);
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/company/getCompany",
        {
          COMPANY_CODE: bCompanyCode,
        },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
          responseType: "json",
        },
      )
      .then(async res => {
        setCompanyName(res.data.data.COMPANY_NAME);
        setCompanyRegNum(res.data.data.REG_NUM);
        setCompanyZipNum(res.data.data.ZIP_NUM);
        setCompanyAddress(res.data.data.ADDRESS + res.data.data.ADDRESS_DETAIL);
      });
  };

  const linkEditorProjectNameBtn = async e => {
    let projectNameClick = e.target.getAttribute("data-arg3");
    let userCode = props.userData.USER_CODE;
    let companyCode = props.userData.COMPANY_CODE;
    console.log(projectNameClick);

    // form 만들어서 보내기
    const frm = document.createElement("form");
    frm.setAttribute("id", "auth-form");
    frm.setAttribute("action", process.env.REACT_APP_EDITOR_URL + "/workspace");
    frm.setAttribute("method", "post");
    frm.setAttribute("target", "auth-form");
    let input1 = document.createElement("input");
    let input2 = document.createElement("input");
    let input3 = document.createElement("input");
    let input4 = document.createElement("input");
    input1.name = "rc";
    input1.value = projectNameClick;
    frm.appendChild(input1);
    input2.name = "uc";
    input2.value = userCode;
    frm.appendChild(input2);
    input3.name = "cc";
    input3.value = companyCode;
    frm.appendChild(input3);
    input4.name = "token";
    input4.value = getCookie("xToken");
    frm.appendChild(input4);
    frm.enctype = "application/json";
    document.body.appendChild(frm);
    window.open("about:blank", "auth-form");
    frm.submit();
    document.body.removeChild(frm);
  };

  const linkEditorTemplateNameBtn = async e => {
    let userCode = props.userData.USER_CODE;
    let companyCode = props.userData.COMPANY_CODE;

    // form 만들어서 보내기
    const frm = document.createElement("form");
    frm.setAttribute("id", "auth-form");
    frm.setAttribute("action", process.env.REACT_APP_EDITOR_URL + "/workspace");
    frm.setAttribute("method", "post");
    frm.setAttribute("target", "auth-form");
    let input5 = document.createElement("input");
    let input6 = document.createElement("input");
    let input7 = document.createElement("input");
    let input8 = document.createElement("input");
    input5.name = "rc";
    input5.value = templateNameClickLink;
    frm.appendChild(input5);
    input6.name = "uc";
    input6.value = userCode;
    frm.appendChild(input6);
    input7.name = "cc";
    input7.value = companyCode;
    frm.appendChild(input7);
    input8.name = "token";
    input8.value = getCookie("xToken");
    frm.appendChild(input8);
    frm.enctype = "application/json";
    document.body.appendChild(frm);
    window.open("about:blank", "auth-form");
    frm.submit();
    document.body.removeChild(frm);
    toggleTamplateDetail();
  };

  const templateNameClick = e => {
    toggleTamplateDetail();
    let templateCode = e.target.getAttribute("data-arg5");
    let snapShotJson = JSON.parse(templateCode);
    let templateNameClickLink = e.target.getAttribute("data-arg6");
    console.log(templateNameClickLink);
    setTemplateNameClickLink(templateNameClickLink);
    setTemplateSnapShot(snapShotJson[0]);
  };
  const linkEditorBlockNameBtn = async e => {
    let userCode = props.userData.USER_CODE;
    let companyCode = props.userData.COMPANY_CODE;
    // form 만들어서 보내기
    const frm = document.createElement("form");
    frm.setAttribute("id", "auth-form");
    frm.setAttribute("action", process.env.REACT_APP_EDITOR_URL + "/workspace");
    frm.setAttribute("method", "post");
    frm.setAttribute("target", "auth-form");
    let input9 = document.createElement("input");
    let input10 = document.createElement("input");
    let input11 = document.createElement("input");
    let input12 = document.createElement("input");
    input9.name = "rc";
    input9.value = blockNameClickLink;
    frm.appendChild(input9);
    input10.name = "uc";
    input10.value = userCode;
    frm.appendChild(input10);
    input11.name = "cc";
    input11.value = companyCode;
    frm.appendChild(input11);
    input12.name = "token";
    input12.value = getCookie("xToken");
    frm.appendChild(input12);
    frm.enctype = "application/json";
    document.body.appendChild(frm);
    window.open("about:blank", "auth-form");
    frm.submit();
    document.body.removeChild(frm);
    toggleBlockDetail();
  };

  const blockNameClick = e => {
    toggleBlockDetail();
    let snapShotCode = e.target.getAttribute("data-arg7");
    let snapShotJson = JSON.parse(snapShotCode);
    console.log(snapShotJson);
    setBlockSnapShot(snapShotJson[0]);
    let blockNameClickLink = e.target.getAttribute("data-arg8");
    console.log(blockNameClickLink);
    setBlockNameClickLink(blockNameClickLink);
  };
  const searchBtn = () => {
    axios
      .post(process.env.REACT_APP_APIKEY + `/api/users/search`, {
        input: searchInput,
        check: check,
        uc: userCode,
      })

      .then(res => {
        if (check === "P") {
          toggleProjectSearch();
          console.log("프로젝트 검색 결과", res.data.data);
          if (res.data.data != null) {
            setSearchProjectDatas(res.data.data.rows);
            setProjectCount(res.data.data.count);
          } else {
            setSearchProjectDatas(null);
            setProjectCount(null);
          }
        } else if (check === "T") {
          toggleTemplateSearch();
          console.log("템플릿 검색 결과", res.data.data);
          if (res.data.data != null) {
            setSearchTemplateDatas(res.data.data.rows);
            setTemplateCount(res.data.data.count);
          } else {
            setSearchTemplateDatas(null);
            setTemplateCount(null);
          }
        } else if (check === "B") {
          toggleBlockSearch();
          console.log("블럭 검색 결과", res.data.data);
          if (res.data.data != null) {
            setSearchBlockDatas(res.data.data.rows);
            setBlockCount(res.data.data.count);
          } else {
            setSearchBlockDatas(null);
            setBlockCount(null);
          }
        } else {
          toggleLists();
          if (res.data.data.resultP != null) {
            setSearchPDatas(res.data.data.resultP.rows);
            setSearchPCount(res.data.data.resultP.count);
          }
          if (res.data.data.resultT != null) {
            setSearchTDatas(res.data.data.resultT.rows);
            setSearchTCount(res.data.data.resultT.count);
          }
        }
      });
  };
  return (
    <>
      <CardBody>
        <CardTitle className="mb-2">{props.t("My Info Search")}</CardTitle>
        <Row>
          <Col sm="1">
            <div>
              <h5 className="font-size-15 text-truncate">{props.t("Search")}</h5>
            </div>
          </Col>

          <Col sm={11}>
            <div className="pt-4">
              <Row>
                <Col>
                  <div className="Container-layout test-box">
                    <div className="form-check mb-3 box-layout">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radios"
                        id="exampleRadios1"
                        value=""
                        onChange={e => {
                          setCheck("");
                        }}
                        defaultChecked
                      />
                      <label className="form-check-label label-box" htmlFor="exampleRadios1">
                        {props.t("All")}
                      </label>
                    </div>

                    <div className="form-check mb-3 box-layout">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radios"
                        id="exampleRadios2"
                        value="P"
                        onChange={e => {
                          setCheck(e.target.value);
                          console.log(e.target.value);
                        }}
                      />
                      <label className="form-check-labe2 label-box" htmlFor="exampleRadios2">
                        {props.t("Project")}
                      </label>
                    </div>

                    <div className="form-check mb-3 box-layout">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radios"
                        id="exampleRadios3"
                        value="T"
                        onChange={e => {
                          setCheck(e.target.value);
                          console.log(e.target.value);
                        }}
                      />
                      <label className="form-check-labe3 label-box" htmlFor="exampleRadios3">
                        {props.t("Template")}
                      </label>
                    </div>

                    <div className="form-check mb-3 box-layout">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radios"
                        id="exampleRadios4"
                        value="B"
                        onChange={e => {
                          setCheck(e.target.value);
                        }}
                      />
                      <label className="form-check-labe4 label-box" htmlFor="exampleRadios4">
                        {props.t("Block")}
                      </label>
                    </div>
                    <div className="col-md-8 box-layout" style={{ width: "270px", marginRight: "30px" }}>
                      <input
                        className="form-control"
                        type="text"
                        placeholder={props.t("Please enter your search term.")}
                        onChange={e => {
                          setSearchInput(e.target.value);
                        }}
                      />
                    </div>
                    <div className="box-layout ">
                      <Button
                        onClick={() => {
                          searchBtn();
                        }}
                        color="primary"
                        className="btn btn-primary "
                        style={{ width: "110px" }}
                      >
                        {props.t("Search")}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </CardBody>
      {/* 전체 검색 모달 */}
      <Modal size="xl" isOpen={modalMore} className="exampleModal" toggle={toggleLists} role="dialog" autoFocus={true} centered={true} tabIndex="-1">
        <div className="modal-content">
          <ModalHeader toggle={toggleLists}>{props.t("Project List")}</ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <p>프로젝트</p>
              <Table className="table align-middle table-nowrap">
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th>{props.t("Company Name")}</th>
                    <th>{props.t("Project Name")}</th>
                    <th>{props.t("Mart Name")}</th>
                    <th>{props.t("Progress")}</th>
                    <th>{props.t("Last work date")}</th>
                    <th>{props.t("Contact history")}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentAllProjectPosts ? (
                    currentAllProjectPosts.map((searchPData, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">{searchPData.row_num}</th>
                          <td className="curserStyle" onClick={viewCompanyBtn} data-arg1={searchPData.A_COMPANY_CODE}>
                            {searchPData.AGENCY_NAME}
                          </td>
                          <td
                          // onClick={linkEditorProjectNameBtn}
                          // data-arg3={searchPData.PROJECT_CODE}
                          >
                            {searchPData.PROJECT_NAME}
                            <Button
                              className="bx btn-primary btn-sm btn-rounded bx-link-external"
                              color="primary"
                              onClick={linkEditorProjectNameBtn}
                              data-arg3={searchPData.PROJECT_CODE}
                              style={btnStyle}
                            ></Button>
                          </td>
                          <td className="curserStyle" onClick={viewMartBtn} data-arg2={searchPData.M_COMPANY_CODE}>
                            {searchPData.MART_NAME}
                          </td>
                          <td>
                            {(() => {
                              switch (searchPData.PROJECT_STEP) {
                                case "RQ":
                                  return "고객 요청";
                                  break;
                                case "PR":
                                  return "제안";
                                  break;
                                case "WK":
                                  return "작업";
                                  break;
                                case "RF":
                                  return "검수 요청";
                                  break;
                                case "RP":
                                  return "인쇄 요청";
                                  break;
                                case "CP":
                                  return "인쇄 완료";
                                  break;
                                case "DV":
                                  return "배송 중";
                                  break;
                              }
                            })()}
                          </td>
                          <td>{moment(searchPData.M_TIME).format("YYYY-MM-DD")}</td>
                          <td className="curserStyle" onClick={communicationBtn} data-arg4={searchPData.PROJECT_CODE}>
                            {searchPData.COMMENT_COUNT} 회
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>검색결과가 없습니다.</td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {searchPCount && searchPCount ? (
                <Pagination
                  className="ant-pagination d-flex justify-content-center"
                  total={searchPCount}
                  current={currentPPage}
                  pageSize={postsPerPage}
                  onChange={page => setCurrentPPage(page)}
                />
              ) : (
                <div></div>
              )}
              <p>{props.t("Template & Block")}</p>
              <Table className="table align-middle table-nowrap">
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th>{props.t("Division")}</th>
                    <th>{props.t("Subject")}</th>
                    <th>{props.t("Create Date")}</th>
                    <th>{props.t("Usage history")}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentAllTemplatePosts ? (
                    currentAllTemplatePosts.map((searchTData, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">{searchTData.row_num}</th>
                          <td>{searchTData.TEMPLATE_TYPE == "T" ? "템플릿" : "블럭"}</td>
                          <td>
                            {searchTData.TEMPLATE_NAME}
                            <Button
                              className="bx btn-primary btn-sm btn-rounded bx-link-external"
                              color="primary"
                              style={btnStyle}
                              onClick={templateNameClick}
                              data-arg5={searchTData.TEMPLATE_SNAPSHOT}
                              data-arg6={searchTData.TEMPLATE_CODE}
                            ></Button>
                          </td>
                          <td>{moment(searchTData.M_TIME).format("YYYY-MM-DD")}</td>
                          <td>{searchTData.TEMPLATE_USE_COUNT}건</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>검색결과가 없습니다.</td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {searchTCount && searchTCount ? (
              <Pagination
                className="ant-pagination d-flex justify-content-center"
                total={searchTCount}
                current={currentTPage}
                pageSize={postsPerPage}
                onChange={page => setCurrentTPage(page)}
              />
            ) : (
              <div></div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggleLists}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/* 프로젝트 검색 모달 */}
      <Modal size="xl" isOpen={modalProject} className="exampleModal" toggle={toggleProjectSearch} role="dialog" autoFocus={true} centered={true} tabIndex="-1">
        <div className="modal-content">
          <ModalHeader toggle={toggleProjectSearch}>{props.t("Project List")}</ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <Table className="table align-middle table-nowrap">
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th>{props.t("Company Name")}</th>
                    <th>{props.t("Project Name")}</th>
                    <th>{props.t("Mart Name")}</th>
                    <th>{props.t("Progress")}</th>
                    <th>{props.t("Last work date")}</th>
                    <th>{props.t("Contact history")}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentProjectPosts ? (
                    currentProjectPosts.map((data, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">{data.row_num}</th>
                          <td className="curserStyle" onClick={viewCompanyBtn} data-arg1={data.A_COMPANY_CODE}>
                            {data.AGENCY_NAME}
                          </td>
                          <td>
                            {data.PROJECT_NAME}
                            <Button
                              className="bx btn-primary btn-sm btn-rounded bx-link-external"
                              color="primary"
                              onClick={linkEditorProjectNameBtn}
                              data-arg3={data.PROJECT_CODE}
                              style={btnStyle}
                            ></Button>
                          </td>
                          <td className="curserStyle" onClick={viewMartBtn} data-arg2={data.M_COMPANY_CODE}>
                            {data.MART_NAME}
                          </td>
                          <td>
                            {(() => {
                              switch (data.PROJECT_STEP) {
                                case "RQ":
                                  return "고객 요청";
                                  break;
                                case "PR":
                                  return "제안";
                                  break;
                                case "WK":
                                  return "작업";
                                  break;
                                case "RF":
                                  return "검수 요청";
                                  break;
                                case "RP":
                                  return "인쇄 요청";
                                  break;
                                case "CP":
                                  return "인쇄 완료";
                                  break;
                                case "DV":
                                  return "배송 중";
                                  break;
                              }
                            })()}
                          </td>
                          <td>{moment(data.M_TIME).format("YYYY-MM-DD")}</td>
                          <td className="curserStyle" onClick={communicationBtn} data-arg4={data.PROJECT_CODE}>
                            {data.COMMENT_COUNT} 회
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>검색결과가 없습니다.</td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {projectCount && projectCount ? (
              <Pagination
                className="ant-pagination d-flex justify-content-center"
                total={projectCount}
                current={currentPage}
                pageSize={postsPerPage}
                onChange={page => setCurrentPage(page)}
              />
            ) : (
              <div></div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggleProjectSearch}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/* 템플릿 검색 모달 */}
      <Modal
        size="xl"
        isOpen={modalTemplate}
        className="exampleModal"
        toggle={toggleTemplateSearch}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleTemplateSearch}>{props.t("Template List")}</ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <Table className="table align-middle table-nowrap">
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th>{props.t("Template Name")}</th>
                    <th>{props.t("Description")}</th>
                    <th>{props.t("Recently work date")}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTemplatePosts ? (
                    currentTemplatePosts.map((data, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">{data.row_num}</th>
                          <td>
                            {data.TEMPLATE_NAME}
                            <Button
                              className="bx btn-primary btn-sm btn-rounded bx-link-external"
                              color="primary"
                              style={btnStyle}
                              onClick={templateNameClick}
                              data-arg5={data.TEMPLATE_SNAPSHOT}
                              data-arg6={data.TEMPLATE_CODE}
                            ></Button>
                          </td>
                          <td>{data.TEMPLATE_DESCRIPTION}</td>
                          <td>{moment(data.M_TIME).format("YYYY-MM-DD")}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>검색결과가 없습니다.</td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {templateCount && templateCount ? (
                <Pagination
                  className="ant-pagination d-flex justify-content-center"
                  total={templateCount}
                  current={currentPage}
                  pageSize={postsPerPage}
                  onChange={page => setCurrentPage(page)}
                />
              ) : (
                <div></div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggleTemplateSearch}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/* 블럭 검색 모달 */}
      <Modal size="xl" isOpen={modalBlock} className="exampleModal" toggle={toggleBlockSearch} role="dialog" autoFocus={true} centered={true} tabIndex="-1">
        <div className="modal-content">
          <ModalHeader toggle={toggleBlockSearch}>{props.t("Block List")}</ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <Table className="table align-middle table-nowrap">
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th>{props.t("Block Name")}</th>
                    <th>{props.t("Description")}</th>
                    <th>{props.t("Recently work date")}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentBlockPosts ? (
                    currentBlockPosts.map((data, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">{data.row_num}</th>
                          <td>
                            {data.TEMPLATE_NAME}
                            <Button
                              className="bx btn-primary btn-sm btn-rounded bx-link-external"
                              color="primary"
                              style={btnStyle}
                              onClick={blockNameClick}
                              data-arg7={data.TEMPLATE_SNAPSHOT}
                              data-arg8={data.TEMPLATE_CODE}
                            ></Button>
                          </td>
                          <td>{data.TEMPLATE_DESCRIPTION}</td>
                          <td>{moment(data.M_TIME).format("YYYY-MM-DD")}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>검색결과가 없습니다.</td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {blockCount && blockCount ? (
                <Pagination
                  className="ant-pagination d-flex justify-content-center"
                  total={blockCount}
                  current={currentPage}
                  pageSize={postsPerPage}
                  onChange={page => setCurrentPage(page)}
                />
              ) : (
                <div></div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggleBlockSearch}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/* 회사정보 모달 */}
      <Modal
        size="xl"
        isOpen={viewCompanyModal}
        className="exampleModal"
        toggle={toggleViewCompany}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleViewCompany}>{props.t("Company Info")}</ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <Table className="table-nowrap mb-0">
                <tbody>
                  <tr>
                    <th scope="row">{props.t("Company Name")} :</th>
                    <td>{companyName}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Registration Number")} :</th>
                    <td>{companyRegNum}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Zip Number")} :</th>
                    <td>{companyZipNum}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Address")} :</th>
                    <td>{companyAddress}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={toggleViewCompany}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/* 마트정보 모달 */}
      <Modal size="xl" isOpen={viewMartModal} className="exampleModal" toggle={toggleViewMart} role="dialog" autoFocus={true} centered={true} tabIndex="-1">
        <div className="modal-content">
          <ModalHeader toggle={toggleViewMart}>{props.t("Mart Info")}</ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <Table className="table-nowrap mb-0">
                <tbody>
                  <tr>
                    <th scope="row">{props.t("Mart Name")} :</th>
                    <td>{companyName}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("  Registration Number")} :</th>
                    <td>{companyRegNum}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Zip Number")} :</th>
                    <td>{companyZipNum}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Address")} :</th>
                    <td>{companyAddress}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={toggleViewMart}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/* 커뮤니케이션 모달 */}
      <Modal isOpen={communicationModal} className="exampleModal" toggle={toggleCommunication} role="dialog" autoFocus={true} centered={true} tabIndex="-1">
        <div className="modal-content">
          <ModalHeader toggle={toggleCommunication}>{props.t("Contact history")}</ModalHeader>
          <ModalBody>
            <HistoryComment companyData={companyNum} commentDates={commentDates} />
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={toggleCommunication}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/* 템플릿 디테일 모달 */}
      <Modal
        size="lg"
        isOpen={templateDetailModal}
        className="exampleModal"
        toggle={toggleTamplateDetail}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleTamplateDetail}>{props.t("Template Preview")}</ModalHeader>
          <ModalBody style={imagestyle}>
            <Container>
              <Row>
                <Col xs={12} md={12}>
                  {templateSnapShot ? <img style={imgStyle} src={process.env.REACT_APP_APIKEY + `/${templateSnapShot}`}></img> : ""}
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={linkEditorTemplateNameBtn}>
              {props.t("Editor")}
            </Button>
            <Button type="button" color="primary" onClick={toggleTamplateDetail}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/* 블럭 디테일 모달 */}
      <Modal
        size="lg"
        isOpen={blockDetailModal}
        className="exampleModal"
        toggle={toggleBlockDetail}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleBlockDetail}>{props.t("Block Preview")}</ModalHeader>
          <ModalBody style={imagestyle}>
            <Container>
              <Row>
                <Col xs={12} md={12}>
                  {blockSnapShot ? <img style={imgStyle} src={process.env.REACT_APP_APIKEY + `/${blockSnapShot}`}></img> : ""}
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={linkEditorBlockNameBtn}>
              {props.t("editor")}
            </Button>
            <Button type="button" color="primary" onClick={toggleBlockDetail}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
}

// export default InfoSearch;
InfoSearch.propTypes = {
  userData: PropTypes.any,
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(InfoSearch));
