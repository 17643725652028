import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Button, CardTitle, Table, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from "reactstrap";
import { getCookie } from "../../common/utils/Cookie";
import moment from "moment";
import axios from "axios";
import "rc-pagination/assets/index.css";
import Pagination from "rc-pagination";
import HistoryComment from "./HistoryComment/HistoryComment";
import "./index.css";
// console.log(indexStyle);
//i18n
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
function MyProjectList(props) {
  const btnStyle = {
    margin: "10px",
  };
  // const curserStyle = { cursor: "pointer" };
  const [createProjectModal, setCreateProjectModal] = useState(false);
  const [viewCompanyModal, setViewCompanyModal] = useState(false);
  const [viewMartModal, setViewMartModal] = useState(false);
  const [moreProjectModal, setMoreProjectModal] = useState(false);
  const [communicationModal, setCommunicationModal] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectNameClick, setProjectNameClick] = useState("");

  const [projectDescription, setProjectDescription] = useState("");
  const [martCode, setMartCode] = useState("");
  const [aCompanyCode, setACompanyCode] = useState("");
  const [mCompanyCode, setMCompanyCode] = useState("");
  // const [companyCode, setCompanyCode] = useState("");
  const [companyNum, setCompanyNum] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyRegNum, setCompanyRegNum] = useState("");
  const [companyZipNum, setCompanyZipNum] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  // 연락내역 갯수
  const [commentLength, setCommentLength] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);

  const [commentDates, setCommentDates] = useState([]);

  const toggleCreateProject = () => {
    setCreateProjectModal(!createProjectModal);
  };
  const createProjectBtn = () => {
    toggleCreateProject();
  };
  const toggleViewCompany = () => {
    setViewCompanyModal(!viewCompanyModal);
  };
  const viewCompanyBtn = async e => {
    toggleViewCompany();
    let aCompanyCode = e.target.getAttribute("data-arg1");
    setACompanyCode(aCompanyCode);
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/company/getCompany",
        {
          COMPANY_CODE: aCompanyCode,
        },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
          responseType: "json",
        },
      )
      .then(async res => {
        // console.log(res); // 프로젝트코드 리턴받음.
        setCompanyName(res.data.data.COMPANY_NAME);
        setCompanyRegNum(res.data.data.REG_NUM);
        setCompanyZipNum(res.data.data.ZIP_NUM);
        setCompanyAddress(res.data.data.ADDRESS + res.data.data.ADDRESS_DETAIL);
      });
  };
  const toggleViewMart = () => {
    setViewMartModal(!viewMartModal);
  };
  const viewMartBtn = async e => {
    toggleViewMart();
    let bCompanyCode = e.target.getAttribute("data-arg2");
    setMCompanyCode(bCompanyCode);
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/company/getCompany",
        {
          COMPANY_CODE: bCompanyCode,
        },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
          responseType: "json",
        },
      )
      .then(async res => {
        setCompanyName(res.data.data.COMPANY_NAME);
        setCompanyRegNum(res.data.data.REG_NUM);
        setCompanyZipNum(res.data.data.ZIP_NUM);
        setCompanyAddress(res.data.data.ADDRESS + res.data.data.ADDRESS_DETAIL);
      });
  };
  const linkEditorProjectNameBtn = async e => {
    let projectNameClick = e.target.getAttribute("data-arg3");
    let userCode = props.userData.USER_CODE;
    let companyCode = props.userData.COMPANY_CODE;
    console.log(projectNameClick);

    // form 만들어서 보내기
    const frm = document.createElement("form");
    frm.setAttribute("id", "auth-form");
    frm.setAttribute("action", process.env.REACT_APP_EDITOR_URL + "/workspace");
    frm.setAttribute("method", "post");
    frm.setAttribute("target", "auth-form");
    let input1 = document.createElement("input");
    let input2 = document.createElement("input");
    let input3 = document.createElement("input");
    let input4 = document.createElement("input");
    input1.name = "rc";
    input1.value = projectNameClick;
    frm.appendChild(input1);
    input2.name = "uc";
    input2.value = userCode;
    frm.appendChild(input2);
    input3.name = "cc";
    input3.value = companyCode;
    frm.appendChild(input3);
    input4.name = "token";
    input4.value = getCookie("xToken");
    frm.appendChild(input4);
    frm.enctype = "application/json";
    document.body.appendChild(frm);
    window.open("about:blank", "auth-form");
    frm.submit();
    document.body.removeChild(frm);
  };

  const toggleMoreProject = () => {
    setMoreProjectModal(!moreProjectModal);
  };
  const moreProjectBtn = () => {
    toggleMoreProject();
  };

  const toggleCommunication = () => {
    // setCompanyNum(PROJECT_CODE);
    console.log(companyNum);
    setCommunicationModal(!communicationModal);
  };
  const communicationBtn = async e => {
    let projectNumClick = e.target.getAttribute("data-arg4");
    // console.log("프로젝트 코드는 ? ? ? ? ", projectNumClick);
    setCompanyNum(projectNumClick);
    await axios.get(process.env.REACT_APP_APIKEY + `/api/comment/date?PROJECT_CODE=${projectNumClick}`).then(async res => {
      setCommentDates(res.data.data);
    });
    toggleCommunication();
  };

  const createProjectOkayBtn = async () => {
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/project/create",
        {
          PROJECT_NAME: projectName,
          PROJECT_DESCRIPTION: projectDescription,
          A_COMPANY_CODE: props.userData.COMPANY_CODE,
          M_COMPANY_CODE: martCode,
          USER_CODE: props.userData.USER_CODE,
        },
        {
          headers: {
            // Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
          responseType: "json",
        },
      )
      .then(async res => {
        console.log(res); // 프로젝트코드 리턴받음.
        setProjectName("");
        setProjectDescription("");
        setMartCode("");
        toggleCreateProject();
        props.getProjectInfo();
        // window.open(process.env.REACT_APP_APIKEY + `/ops-editor/OPS`, "_blank");
      });
  };

  const projectDescriptionChange = e => {
    setProjectDescription(e.target.value);
  };
  const projectNameChange = e => {
    setProjectName(e.target.value);
  };
  const martCodeChange = e => {
    setMartCode(e.target.value);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = props.projectList != null ? props.projectList.slice(indexOfFirstPost, indexOfLastPost) : [];
  useEffect(() => {}, []);

  return (
    <>
      {/* 프로젝트 생성 모달 */}
      <Modal
        // size="xl"
        isOpen={createProjectModal}
        className="exampleModal"
        toggle={toggleCreateProject}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleCreateProject}>{props.t("New Project")}</ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={12}>
                <div className="row mb-2">
                  <Label htmlFor="horizontal-firstname-Input" className="col-sm-3 col-form-label">
                    {props.t("Project Name")}
                    <em className="required">{props.t("Require")}</em>
                  </Label>
                  <Col sm={8}>
                    <Input
                      defaultValue={projectName || ""}
                      type="text"
                      className="form-control"
                      id="horizontal-name-Input"
                      placeholder="프로젝트명을 입력하세요."
                      onChange={projectNameChange}
                    />
                  </Col>
                </div>
                <div className="row mb-2">
                  <Label htmlFor="horizontal-firstname-Input" className="col-sm-3 col-form-label">
                    {props.t("Mart Code")}
                    <em className="required">{props.t("Require")}</em>
                  </Label>
                  <Col sm={8}>
                    <Input
                      defaultValue={martCode || ""}
                      type="text"
                      className="form-control"
                      id="horizontal-name-Input"
                      placeholder="마트코드를 입력하세요."
                      onChange={martCodeChange}
                    />
                  </Col>
                </div>
                <div className="row mb-2">
                  <Label htmlFor="horizontal-firstname-Input" className="col-sm-3 col-form-label">
                    {props.t("Project Info")}
                  </Label>
                  <Col sm={8}>
                    <Input
                      defaultValue={projectDescription || ""}
                      type="text"
                      className="form-control"
                      id="horizontal-name-Input"
                      placeholder="프로젝트 내용을 입력하세요."
                      onChange={projectDescriptionChange}
                    />
                  </Col>
                </div>
              </Col>
            </Row>
            {/* <div className="table-responsive"></div> */}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={createProjectOkayBtn}>
              {props.t("Make")}
            </Button>
            <Button type="button" color="primary" onClick={toggleCreateProject}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/* 회사정보 모달 */}
      <Modal
        size="xl"
        isOpen={viewCompanyModal}
        className="exampleModal"
        toggle={toggleViewCompany}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleViewCompany}>{props.t("Company Info")}</ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <Table className="table-nowrap mb-0">
                <tbody>
                  <tr>
                    <th scope="row">{props.t("Company Name")} :</th>
                    <td>{companyName}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Registration Number")} :</th>
                    <td>{companyRegNum}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Zip Number")} :</th>
                    <td>{companyZipNum}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Address")} :</th>
                    <td>{companyAddress}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={toggleViewCompany}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/* 마트정보 모달 */}
      <Modal size="xl" isOpen={viewMartModal} className="exampleModal" toggle={toggleViewMart} role="dialog" autoFocus={true} centered={true} tabIndex="-1">
        <div className="modal-content">
          <ModalHeader toggle={toggleViewMart}>{props.t("Mart Info")}</ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <Table className="table-nowrap mb-0">
                <tbody>
                  <tr>
                    <th scope="row">{props.t("Mart Name")} :</th>
                    <td>{companyName}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("  Registration Number")} :</th>
                    <td>{companyRegNum}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Zip Number")} :</th>
                    <td>{companyZipNum}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Address")} :</th>
                    <td>{companyAddress}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={toggleViewMart}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/* 더보기 모달 */}
      <Modal
        size="xl"
        isOpen={moreProjectModal}
        className="exampleModal"
        toggle={toggleMoreProject}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleMoreProject}>{props.t("Project List")}</ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th>{props.t("Company Name")}</th>
                    <th>{props.t("Project Name")}</th>
                    <th>{props.t("Mart Name")}</th>
                    <th>{props.t("Progress")}</th>
                    <th>{props.t("Last work date")}</th>
                    <th>{props.t("Contact history")}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPosts ? (
                    currentPosts.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td scope="row">{data.row_num}</td>

                          <td onClick={viewCompanyBtn} data-arg1={data.A_COMPANY_CODE}>
                            {data.AGENCY_NAME}
                          </td>
                          <td onClick={linkEditorProjectNameBtn} data-arg3={data.PROJECT_CODE}>
                            {data.PROJECT_NAME}
                          </td>
                          <td onClick={viewMartBtn} data-arg2={data.M_COMPANY_CODE}>
                            {data.MART_NAME}
                          </td>
                          <td>
                            {(() => {
                              switch (data.PROJECT_STEP) {
                                case "RQ":
                                  return "고객 요청";
                                  break;
                                case "PR":
                                  return "제안";
                                  break;
                                case "WK":
                                  return "작업";
                                  break;
                                case "RF":
                                  return "검수 요청";
                                  break;
                                case "RP":
                                  return "인쇄 요청";
                                  break;
                                case "CP":
                                  return "인쇄 완료";
                                  break;
                                case "DV":
                                  return "배송 중";
                                  break;
                              }
                            })()}
                          </td>
                          <td>{moment(data.M_TIME).format("YYYY-MM-DD")}</td>
                          <td onClick={communicationBtn} data-arg4={data.PROJECT_CODE}>
                            {data.COMMENT_COUNT} 회
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {props.projectCount && props.projectCount ? (
              <Pagination
                className="ant-pagination d-flex justify-content-center"
                total={props.projectCount}
                current={currentPage}
                pageSize={postsPerPage}
                onChange={page => setCurrentPage(page)}
              />
            ) : (
              <div></div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={toggleMoreProject}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>

      <CardBody>
        <Row>
          <Col>
            <CardTitle className="mb-2">{props.t("Recently Project")} </CardTitle>
          </Col>
          <Col>
            <div align="right">
              <Button className="btn btn-primary" color="primary" style={btnStyle} onClick={createProjectBtn}>
                {props.t("New Project")}
              </Button>
            </div>
          </Col>
        </Row>
        <div className="table-responsive">
          <Table className="table mb-0">
            <thead className="table-light">
              <tr>
                <th>#</th>
                <th>{props.t("Company Name")}</th>
                <th>{props.t("Project Name")}</th>
                <th>{props.t("Mart Name")}</th>
                <th>{props.t("Progress")}</th>
                <th>{props.t("Last work date")}</th>
                <th>{props.t("Contact history")}</th>
              </tr>
            </thead>
            <tbody>
              {props.projectInfo ? (
                props.projectInfo.map((data, index) => {
                  // console.log("----data---", data);
                  return (
                    <tr key={index}>
                      <td scope="row">{index + 1}</td>
                      <td className="curserStyle" onClick={viewCompanyBtn} data-arg1={data.A_COMPANY_CODE}>
                        {/* style={curserStyle} */}
                        {data.AGENCY_NAME}
                      </td>
                      <td>
                        {data.PROJECT_NAME}
                        <Button
                          className="bx btn-primary btn-sm btn-rounded bx-link-external"
                          color="primary"
                          onClick={linkEditorProjectNameBtn}
                          data-arg3={data.PROJECT_CODE}
                          style={btnStyle}
                        ></Button>
                      </td>
                      <td className="curserStyle" onClick={viewMartBtn} data-arg2={data.M_COMPANY_CODE}>
                        {data.MART_NAME}
                      </td>
                      <td>
                        {(() => {
                          switch (data.PROJECT_STEP) {
                            case "RQ":
                              return "고객 요청";
                              break;
                            case "PR":
                              return "제안";
                              break;
                            case "WK":
                              return "작업";
                              break;
                            case "RF":
                              return "검수 요청";
                              break;
                            case "RP":
                              return "인쇄 요청";
                              break;
                            case "CP":
                              return "인쇄 완료";
                              break;
                            case "DV":
                              return "배송 중";
                              break;
                          }
                        })()}
                      </td>
                      <td>{moment(data.M_TIME).format("YYYY-MM-DD")}</td>
                      <td className="curserStyle" onClick={communicationBtn} data-arg4={data.PROJECT_CODE}>
                        {data.COMMENT_COUNT} 회
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </CardBody>
      <Button color="primary" onClick={moreProjectBtn}>
        {props.t("Show All")}
      </Button>
      {/* 연락내역 모달 */}
      <Modal isOpen={communicationModal} className="exampleModal" toggle={toggleCommunication} role="dialog" autoFocus={true} centered={true} tabIndex="-1">
        <div className="modal-content">
          <ModalHeader toggle={toggleCommunication}>{props.t("Contact history")}</ModalHeader>
          <ModalBody>
            <HistoryComment companyData={companyNum} commentDates={commentDates} />
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={toggleCommunication}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
}

// export default MyProjectList;
MyProjectList.propTypes = {
  userData: PropTypes.any,
  companyList: PropTypes.any,
  projectList: PropTypes.any,
  projectInfo: PropTypes.any,
  listProject: PropTypes.any,
  projectCount: PropTypes.any,
  getProjectInfo: PropTypes.any,
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(MyProjectList));
