import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Input,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";

const TabService = props => {
  const [service, setService] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("22");
  const [listOption, setListOption] = useState([]);
  const [Selected, setSelected] = useState("22");
  const handleSelect = e => {
    setSelected(e.target.value);
    console.log(Selected);
  };
  let optionGroup = [
    { label: "2022-02-09", value: "22" },
    { label: "2022-02-07", value: "11" },
    { label: "2022-02-01", value: "33" },
  ];

  const groupOnChange = value => {
    setSelectedGroup(value);
    console.log(selectedGroup);
  };
  const listOptionArr = async () => {
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/terms/listSelectBoxJson",
        {
          title: "서비스 약관",
        },
        {
          headers: {
            contentType: "application/json",
            userAgent: "DEVICE-AGENT",
          },
        },
      )
      .then(response => {
        // console.log(response.data.data);
        setListOption(response.data.data);
        console.log(listOption);
      });
  };
  const getTerms = async () => {
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/terms/getService",
        {
          textCode: Selected,
        },
        {
          headers: {
            contentType: "application/json",
            userAgent: "DEVICE-AGENT",
          },
        },
      )
      .then(response => {
        setService(response.data.data);
      });
  };

  useEffect(() => {
    listOptionArr();
  }, []);
  useEffect(() => {
    getTerms();
    console.log(listOption);
    // console.log(listOption);
  }, [Selected]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Form Layouts | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">서비스 이용약관</CardTitle>
                  <div dangerouslySetInnerHTML={{ __html: service }}></div>
                </CardBody>
                <select onChange={handleSelect} value={Selected}>
                  {listOption &&
                    listOption.map(item => (
                      <option value={item.TEXT_CODE} key={item.M_TIME}>
                        {item.M_TIME}
                      </option>
                    ))}
                </select>
              </Card>
              {/* <Select
                value={optionGroup.find(op => {
                  return op.value === selectedGroup;
                })}
                onChange={selectedGroup => {
                  groupOnChange(selectedGroup.value);
                }}
                classNamePrefix="select2-selection"
                options={optionGroup}
              /> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
TabService.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default TabService;
