import PropTypes from "prop-types";
import React from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";
// userData store
import UserStore from "store/Store";
import { connect } from "react-redux";

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import fakeBackend from "./helpers/AuthType/fakeBackend";

const queryClient = new QueryClient();
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"
// const UserContext = React.createContext(userInfo);

// Activating fake backend
fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.                      ,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = props => {
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <QueryClientProvider client={queryClient}>
      {/* <UserStore> */}
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware path={route.path} layout={NonAuthLayout} component={route.component} key={idx} isAuthProtected={false} exact />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware path={route.path} layout={Layout} component={route.component} key={idx} isAuthProtected={true} exact />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
      {/* </UserStore> */}
    </QueryClientProvider>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
