import React, { useState } from "react";
import { CardBody, CardTitle, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Input, Label, Form, Card } from "reactstrap";
import { useQuery } from "react-query";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { getCookie } from "../../common/utils/Cookie";
import PropTypes from "prop-types";

//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

function UserInfo(props) {
  const location = useLocation();
  const userCode = getCookie("userCode") || location.state.userCode;
  const userInfo = props.userInfo;

  const [inputs, setInputs] = useState({
    USER_NAME: null,
    USER_POSITION: null,
    ADD_PHONE_NUM: null,
  });

  const [modalMore, setModalMore] = useState(false);
  const toggleLists = () => {
    setModalMore(!modalMore);
  };
  const [fetch, setFetch] = useState(null);
  const userInfoGet = () => {
    axios.post(process.env.REACT_APP_APIKEY + `/api/users/get`, { USER_CODE: userCode }).then(res => {
      res.data.data;
      setInputs(res.data.data);
    });
  };
  const { data: userInfoData, refetch } = useQuery("user", userInfoGet);
  const onClick = () => {
    refetch();
    toggleLists();
  };
  const userInfoUpdate = () => {
    axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/users/userupdate",
        {
          USER_CODE: userInfo.USER_CODE,
          USER_NAME: inputs.USER_NAME,
          USER_POSITION: inputs.USER_POSITION,
          ADD_PHONE_NUM: inputs.ADD_PHONE_NUM,
        },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
      )
      .then(res => {
        console.log(res);
        if (res.data.result === "success") {
          alert("유저 수정에 성공하셨습니다.");
        } else {
          alert("유저 수정에 실패하셨습니다.");
        }
      });
  };
  const nameOnchage = e => {
    setInputs({
      USER_NAME: e.target.value,
      USER_POSITION: inputs.USER_POSITION,
      ADD_PHONE_NUM: inputs.ADD_PHONE_NUM,
    });
  };
  const positonOnchage = e => {
    setInputs({
      USER_NAME: inputs.USER_NAME,
      USER_POSITION: e.target.value,
      ADD_PHONE_NUM: inputs.ADD_PHONE_NUM,
    });
  };
  const addPhoneOnchage = e => {
    setInputs({
      USER_NAME: inputs.USER_NAME,
      USER_POSITION: inputs.USER_POSITION,
      ADD_PHONE_NUM: e.target.value,
    });
  };

  return (
    <>
      <CardBody>
        <CardTitle className="mb-2">
          {props.t("MyPage User Info")}{" "}
          <Button color="primary" className="btn-sm" style={{ float: "right" }} onClick={() => onClick()}>
            {props.t("Edit")}
          </Button>
        </CardTitle>
        <div className="table-responsive">
          <Table className="table-nowrap mb-0">
            <tbody>
              <tr>
                <th scope="row">{props.t("Name")} :</th>
                <td>{userInfo?.USER_NAME}</td>
              </tr>
              <tr>
                <th scope="row">{props.t("Phone")} :</th>
                <td>{userInfo?.PHONE_NUM}</td>
              </tr>
              <tr>
                <th scope="row">{props.t("E-mail")} :</th>
                <td>{userInfo?.USER_EMAIL}</td>
              </tr>
              <tr>
                <th scope="row">{props.t("Phone2")} :</th>
                <td>{userInfo?.ADD_PHONE_NUM}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </CardBody>
      <Modal
        size="ml"
        isOpen={modalMore}
        className="exampleModal"
        toggle={toggleLists}
        // backdrop={false}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleLists}>{props.t("Profile Edit")}</ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Form>
                        <div className="row mb-4">
                          <Label htmlFor="horizontal-firstname-Input" className="col-sm-4 col-form-label">
                            {props.t("E-mail")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="horizontal-firstname-Input"
                              name="USER_EMAIL"
                              defaultValue={userInfo?.USER_EMAIL}
                              disabled
                            />
                          </Col>
                        </div>

                        <div className="row mb-4">
                          <Label htmlFor="horizontal-name-Input" className="col-sm-4 col-form-label">
                            {props.t("Name")}
                          </Label>
                          <Col sm={8}>
                            <Input type="text" className="form-control" id="USER_NAME" name="USER_NAME" value={inputs?.USER_NAME} onChange={nameOnchage} />
                          </Col>
                        </div>

                        <div className="row mb-4">
                          <Label htmlFor="horizontal-email-Input" className="col-sm-4 col-form-label">
                            {props.t("Phone")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="horizontal-email-Input"
                              name="PHONE_NUM"
                              defaultValue={userInfo?.PHONE_NUM}
                              disabled
                            />
                          </Col>
                        </div>

                        <div className="row mb-4">
                          <Label htmlFor="horizontal-add-phonenum-Input" className="col-sm-4 col-form-label">
                            {props.t("Phone2")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="horizontal-add-phonenum-Input"
                              name="ADD_PHONE_NUM"
                              value={inputs?.ADD_PHONE_NUM}
                              onChange={addPhoneOnchage}
                            />
                          </Col>
                        </div>

                        <div className="row mb-4">
                          <Label htmlFor="horizontal-position-Input" className="col-sm-4 col-form-label">
                            {props.t("Job")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="USER_POSITION"
                              name="USER_POSITION"
                              value={inputs?.USER_POSITION}
                              onChange={positonOnchage}
                            />
                          </Col>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                userInfoUpdate();
                toggleLists();
              }}
            >
              {props.t("Edit")}
            </Button>
            <Button type="button" color="secondary" onClick={toggleLists}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
}
UserInfo.propTypes = {
  userInfo: PropTypes.any,
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UserInfo));
