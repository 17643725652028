import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Input, Container, Row, Col, Button, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Table, CardTitle } from "reactstrap";
import moment from "moment";

import "rc-pagination/assets/index.css";
import Pagination from "rc-pagination";
import { getCookie } from "../../common/utils/Cookie";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

function MyTemplate(props) {
  const btnStyle = {
    margin: "10px",
  };

  const history = useHistory();
  const [templateNameClickLink, setTemplateNameClickLink] = useState("");
  const [templateSnapShot, setTemplateSnapShot] = useState("");
  const [modalMore, setModalMore] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const [selectedTemplate, setSelectedTemplate] = useState("L");
  const toggleDetail = () => setModalDetail(!modalDetail);
  const toggleLists = () => {
    setModalMore(!modalMore);
  };
  const templateNameClick = e => {
    toggleDetail();
    let templateCode = e.target.getAttribute("data-arg1");
    let snapShotJson = JSON.parse(templateCode);
    let templateNameClickLink = e.target.getAttribute("data-arg2");
    console.log(templateNameClickLink);
    setTemplateNameClickLink(templateNameClickLink);
    setTemplateSnapShot(snapShotJson[0]);
  };
  const linkEditorTemplateNameBtn = async e => {
    let userCode = props.userData.USER_CODE;
    let companyCode = props.userData.COMPANY_CODE;

    // form 만들어서 보내기
    const frm = document.createElement("form");
    frm.setAttribute("id", "auth-form");
    frm.setAttribute("action", process.env.REACT_APP_EDITOR_URL + "/workspace");
    frm.setAttribute("method", "post");
    frm.setAttribute("target", "auth-form");
    let input1 = document.createElement("input");
    let input2 = document.createElement("input");
    let input3 = document.createElement("input");
    let input4 = document.createElement("input");
    input1.name = "rc";
    input1.value = templateNameClickLink;
    frm.appendChild(input1);
    input2.name = "uc";
    input2.value = userCode;
    frm.appendChild(input2);
    input3.name = "cc";
    input3.value = companyCode;
    frm.appendChild(input3);
    input4.name = "token";
    input4.value = getCookie("xToken");
    frm.appendChild(input4);
    frm.enctype = "application/json";
    document.body.appendChild(frm);
    window.open("about:blank", "auth-form");
    frm.submit();
    document.body.removeChild(frm);
    toggleDetail();
  };
  const firstTemplateRadio = e => {
    props.setTemplatePublic(e.target.value);
    setSelectedTemplate(e.target.value);
  };
  const secondTemplateRadio = e => {
    props.setTemplatePublic(e.target.value);
    setSelectedTemplate(e.target.value);
  };
  useEffect(() => {
    props.getTemplateInfo();
    props.listTemplate();
  }, [selectedTemplate]);

  const viewMoreTemplate = () => {
    toggleLists();
  };

  useEffect(() => {
    if (!getCookie("xToken")) {
      history.pushState("/");
    }
  }, []);
  const imagestyle = {
    overflow: "auto",
  };
  const imgStyle = {
    position: "relative",
    height: "200px",
    width: "200px",
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = props.templateList != null ? props.templateList.slice(indexOfFirstPost, indexOfLastPost) : [];

  return (
    <>
      <CardBody>
        <Row>
          <Col>
            <CardTitle className="mb-2">{props.t("My Template")}</CardTitle>
            <div className="Container-layout test-box">
              <div className="form-check col-md-3 box-layout">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="templateRadios1"
                  value="L"
                  onChange={firstTemplateRadio}
                  defaultChecked
                />
                <label className="form-check-label" htmlFor="templateRadios1">
                  {props.t("Local")}
                </label>
              </div>

              <div className="form-check col-md-3 box-layout">
                <Input className="form-check-input" type="radio" name="exampleRadios" id="templateRadios2" value="G" onChange={secondTemplateRadio} />
                <label className="form-check-labe2" htmlFor="templateRadios2">
                  {props.t("Global")}
                </label>
              </div>
            </div>
          </Col>
        </Row>
        <div className="table-responsive">
          <Table className="table mb-0">
            <thead className="table-light">
              <tr>
                <th>#</th>
                <th>{props.t("Template Name")}</th>
                <th>{props.t("Create Date")}</th>
                <th>{props.t("Usage history")}</th>
              </tr>
            </thead>
            <tbody>
              {props.templateInfo != null ? (
                props.templateInfo.map((data, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      {/* <td onClick={templateNameClick} data-arg1={data.TEMPLATE_SNAPSHOT} data-arg2={data.TEMPLATE_CODE}> */}
                      <td>
                        {data.TEMPLATE_NAME}
                        <Button
                          className="bx btn-primary btn-sm btn-rounded bx-link-external"
                          color="primary"
                          style={btnStyle}
                          onClick={templateNameClick}
                          data-arg1={data.TEMPLATE_SNAPSHOT}
                          data-arg2={data.TEMPLATE_CODE}
                        ></Button>
                      </td>
                      <td>{moment(data.M_TIME).format("YYYY-MM-DD")}</td>
                      <td>{data.TEMPLATE_USE_COUNT} 건</td>
                    </tr>
                  );
                })
              ) : (
                <td>데이터가 없습니다.</td>
              )}
            </tbody>
          </Table>
        </div>
      </CardBody>
      <Button color="primary" onClick={viewMoreTemplate}>
        {props.t("Show All")}
      </Button>

      <Modal size="xl" isOpen={modalMore} className="exampleModal" toggle={toggleLists} role="dialog" autoFocus={true} centered={true} tabIndex="-1">
        <div className="modal-content">
          <ModalHeader toggle={toggleLists}>{props.t("Template List")}</ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <Table className="table align-middle table-nowrap">
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th>{props.t("Template Name")}</th>
                    <th>{props.t("Create Date")}</th>
                    <th>{props.t("Usage history")}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPosts ? (
                    currentPosts.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td scope="row">{data.row_num}</td>

                          <td>
                            {data.TEMPLATE_NAME}
                            <Button
                              className="bx btn-primary btn-sm btn-rounded bx-link-external"
                              color="primary"
                              style={btnStyle}
                              onClick={templateNameClick}
                              data-arg1={data.TEMPLATE_SNAPSHOT}
                              data-arg2={data.TEMPLATE_CODE}
                            ></Button>
                          </td>
                          <td>{moment(data.M_TIME).format("YYYY-MM-DD")}</td>
                          <td>{data.TEMPLATE_USE_COUNT} 건</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {props.templateCount && props.templateCount ? (
              <Pagination
                className="ant-pagination d-flex justify-content-center"
                total={props.templateCount}
                current={currentPage}
                pageSize={postsPerPage}
                onChange={page => setCurrentPage(page)}
              />
            ) : (
              <div></div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={toggleLists}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>

      <Modal size="lg" isOpen={modalDetail} className="exampleModal" toggle={toggleDetail} role="dialog" autoFocus={true} centered={true} tabIndex="-1">
        <div className="modal-content">
          <ModalHeader toggle={toggleDetail}>{props.t("Template Preview")}</ModalHeader>
          <ModalBody style={imagestyle}>
            <Container>
              <Row>
                <Col xs={12} md={12}>
                  {templateSnapShot ? <img style={imgStyle} src={process.env.REACT_APP_APIKEY + `/${templateSnapShot}`}></img> : ""}
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={linkEditorTemplateNameBtn}>
              {props.t("Editor")}
            </Button>
            <Button type="button" color="primary" onClick={toggleDetail}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
}

MyTemplate.propTypes = {
  templateInfo: PropTypes.any,
  templateList: PropTypes.any,
  templateCount: PropTypes.any,
  getTemplateInfo: PropTypes.any,
  listTemplate: PropTypes.any,
  setTemplatePublic: PropTypes.any,
  templatePublic: PropTypes.any,
  userData: PropTypes.any,
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(MyTemplate));
