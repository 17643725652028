import React, { useState, useEffect, useCallback, useRef } from "react";
import { CardBody, Button, Modal, ModalHeader, UncontrolledTooltip, ModalBody, ModalFooter, Container, Row, Col, Card } from "reactstrap";
import { useParams, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import MetaTags from "react-meta-tags";
import ImageMarker from "react-image-marker";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useMutation, useQuery } from "react-query";
import EffectMarker from "./EffectMarker";
import PropTypes from "prop-types";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { getCookie } from "common/utils/Cookie";
import { getUserAPI, userAPI } from "common/api/user";
let myUserCode = getCookie("userCode");

function MarkerInfo({}) {
  let { seq } = useParams();
  const location = useLocation();
  const textRef = useRef();
  const [infoGets, setInfoGets] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [marker, setMarker] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [Xclick, setXclick] = useState("");
  const [Yclick, setYclick] = useState("");
  const [project, setProject] = useState("");
  const [snapProject, setSnapProject] = useState("");
  const userCode = getCookie("userCode") || location.state.userCode;
  const [inputs, setInputs] = useState([{ uploadFile: null, fileName: "", COMMENT_IMG: null }]);
  const { uploadFile, fileName, COMMENT_IMG } = inputs;
  const [userInfo, setUserInfo] = useState(null);
  const [showImg, setShowImg] = useState(true);

  // const { data: userInfo } = useQuery("users", () => getUserAPI());

  const hideImg = e => {
    setShowImg(false);
  };

  const userGetData = async () => {
    await axios.post(process.env.REACT_APP_APIKEY + `/api/users/get`, { USER_CODE: myUserCode }).then(res => {
      setUserInfo(res.data.data);
    });
  };

  // 모달
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  // 이모티콘 모달
  const [emojiModal, setEmojiModal] = useState(false);
  const emojiToggle = () => setEmojiModal(!emojiModal);
  // 이미지 모달
  const [imgModal, setImgModal] = useState(false);
  const imgToggle = () => setImgModal(!imgModal);

  const lastSetDatas = async () => {
    await axios.post(process.env.REACT_APP_APIKEY + "/api/comment/lastset", { PROJECT_CODE: seq }).then(res => {
      console.log("마지막 set", res);
      setInfoGets(res.data.data);
    });
  };
  const {
    status,
    data: markerLists,
    error,
    isFetching,
  } = useQuery("points", async () => {
    const res = await axios.get(process.env.REACT_APP_APIKEY + `/api/comment/markerPoints?PROJECT_CODE=${seq}`);
    setMarkers(res.data.data);
    // console.log("테슷트", res.data.data);
    return res.data.data;
  });

  const { data: markerDates } = useQuery("pointsDate", async () => {
    const res = await axios.get(process.env.REACT_APP_APIKEY + `/api/comment/marketDate?PROJECT_CODE=${seq}`);
    // console.log("테슷트", res.data.data);
    return res.data.data;
  });
  // console.log("markerDates ++++++", markerDates);

  useEffect(() => {
    userGetData();
    lastSetDatas();
  }, []);

  // 댓글 추가
  const craeteClick = useMutation(newMarker => {
    if (commentText === "" || commentText === null || commentText === undefined) {
      alert("빈칸을 입력해주세요");
      return false;
    } else {
      axios.post(process.env.REACT_APP_APIKEY + `/api/comment/create`, newMarker).then(res => {
        console.log(res);
        if (res.data.result === "fail") {
          console.log("댓글 추가에 실패하였습니다.");
          return false;
        } else if (res.data.result === "success") {
          alert("댓글 추가에 성공하셨습니다.");
          console.log("댓글추가성공", res);
          setCommentText("");
          modalToggle();
        }
      });
    }
  });
  const handleKeyDown = e => {
    if (e.keyCode === 13 && e.shiftKey) {
      setCommentText(commentText);
    } else if (e.key === "Enter") {
      onCreateClick();
      modalToggle();
    }
  };
  const onCreateClick = e => {
    craeteClick.mutate({
      PROJECT_SNAPSHOT_CODE: snapProject,
      COMMENT_TEXT: commentText,
      COMMENT_XPOINT: Xclick,
      COMMENT_YPOINT: Yclick,
      PROJECT_CODE: seq,
      USER_CODE: myUserCode,
      C_ID: myUserCode,
      M_ID: myUserCode,
      COMPANY_CODE: userInfo?.COMPANY_CODE,
    });
  };
  const resizeHeight = useCallback(() => {
    textRef.current.style.height = textRef.current.scrollHeight + "px";
  }, []);

  const containerStyle = {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    marginBottom: "20px",
    cursor: "pointer",
    position: "relative",
    maxWidth: "100%",
    margin: "0 auto",
    height: "auto",
    border: "2px solid black",
  };

  // 이미지 마커 스타일 적용
  const CustomMarker = markers => <div className="custom-marker">{markers.itemNumber}</div>;
  // 모달 열고 닫기
  const openClick = () => {
    setModal(true);
  };
  function handleFileChange(e) {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: e.target.files[0],
      [fileName]: value,
    });
  }
  function addImage() {
    const url = process.env.REACT_APP_APIKEY + `/api/upload/uploadSingle`;
    const formData = new FormData();
    formData.append("location", "comment");
    formData.append("unique", true);
    formData.append("uploadfile", inputs.uploadFile);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  }
  function handleFormSubmit() {
    addImage().then(res => {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      axios
        .post(
          process.env.REACT_APP_APIKEY + `/api/comment/imgUpload`,
          {
            PROJECT_SNAPSHOT_CODE: snapProject,
            COMMENT_IMG: "PDSdata/uploads/comment/" + res.data.data.filename,
            COMMENT_XPOINT: Xclick,
            COMMENT_YPOINT: Yclick,
            PROJECT_CODE: seq,
            USER_CODE: myUserCode,
            COMPANY_CODE: userInfo?.COMPANY_CODE,
            C_ID: myUserCode,
            M_ID: myUserCode,
          },
          config,
        )
        .then(res => {
          setInputs(res.data);
          setImgModal(false);
          setModal(false);
          alert("이미지가 성공적으로 업로드 되었습니다.");
        })
        .catch(err => console.log(err));
      setInputs({
        uploadFile: null,
        location: "comment",
      });
    });
  }

  const imageData = infoGets ? (
    infoGets?.map(infoGet => {
      return (
        <div key={infoGet.PROJECT_SNAPSHOT_CODE}>
          <TransformWrapper>
            <TransformComponent>
              <div className="frame" id="modalBox" style={containerStyle}>
                <div
                  onClick={e => {
                    openClick();
                    setXclick(e.nativeEvent.offsetX);
                    setProject(infoGet.PROJECT_CODE);
                    setSnapProject(infoGet.PROJECT_SNAPSHOT_CODE);
                    setYclick(e.nativeEvent.offsetY);
                  }}
                >
                  {showImg ? (
                    <ImageMarker
                      src={process.env.REACT_APP_APIKEY + "/" + infoGet.PROJECT_SNAPSHOT_URL}
                      // onError={hideImg}
                      markers={marker}
                      onAddMarker={Marker => setMarkers([...markers, Marker])}
                      markerComponent={CustomMarker}
                    />
                  ) : (
                    <div>이미지가 존재하지 않습니다.</div>
                  )}
                </div>
                {/* {console.log("///", infoGet)} */}
                <EffectMarker markers={markers} markerDates={markerDates} userInfo={userInfo} seq={seq} infoGet={infoGet} />
              </div>
            </TransformComponent>
          </TransformWrapper>
        </div>
      );
    })
  ) : (
    <div>데이터가 없습니다.</div>
  );

  // 이모티콘
  const addEmoji = e => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach(el => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setCommentText(commentText + emoji);
  };
  const emojiStyle = {
    height: "350px",
    marginTop: "270px",
    transition: "0.3s",
    bottom: "5px",
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>SHUKET | COMMENT</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="UI Elements" breadcrumbItem="댓글 작성" />

          <Row>
            <Col>
              {/* style={{ overflowY: "scroll", height: "100vh" }} */}
              <Card>
                <CardBody>{imageData}</CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* 모달 */}
      <Modal isOpen={modal} toggle={modalToggle}>
        <ModalHeader toggle={modalToggle}></ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div>
                <textarea
                  style={{ overflow: "hidden", resize: "none" }}
                  ref={textRef}
                  type="text"
                  name="commentText"
                  value={commentText}
                  onKeyPress={handleKeyDown}
                  onChange={e => setCommentText(e.target.value)}
                  onInput={resizeHeight}
                  className="form-control chat-input"
                  placeholder="댓글을 입력하세요..."
                />
                <div className="chat-input-links">
                  <ul className="list-inline mb-0">
                    {/* <li className="list-inline-item" onClick={emojiToggle} style={{ width: "20px", cursor: "pointer" }}>
                      <i className="mdi mdi-emoticon-happy-outline" id="Emojitooltip" style={{ fontSize: "20px" }} />
                      <UncontrolledTooltip placement="top" target="Emojitooltip">
                        Emojis
                      </UncontrolledTooltip>
                    </li> */}
                    <li className="list-inline-item" onClick={imgToggle} style={{ width: "20px", cursor: "pointer" }}>
                      <i className="mdi mdi-file-image-outline" id="Imagetooltip" style={{ fontSize: "20px" }} />
                      <UncontrolledTooltip placement="top" target="Imagetooltip">
                        image
                      </UncontrolledTooltip>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col className="col-auto">
              <Button type="button" color="primary" onClick={() => onCreateClick()} className="btn btn-primary btn-rounded chat-send w-md ">
                <span className="d-none d-sm-inline-block me-2">Send</span> <i className="mdi mdi-send" />
              </Button>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => modalToggle()}>
            닫기
          </Button>
        </ModalFooter>
      </Modal>
      {/* <Modal isOpen={emojiModal} toggle={emojiToggle} style={emojiStyle}>
        <div>
          <Picker onSelect={addEmoji} autoFocus={true} style={{ width: "498px", height: "350px", padding: 0 }} />
        </div>
      </Modal> */}
      <Modal isOpen={imgModal} toggle={imgToggle} backdrop={false}>
        <ModalHeader toggle={imgToggle}></ModalHeader>
        <ModalBody>
          <input accept="image/*" id="raised-button-file" name="uploadFile" type="file" file={uploadFile} value={fileName} onChange={handleFileChange} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleFormSubmit}>
            업로드
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
MarkerInfo.propTypes = {
  userInfo: PropTypes.array,
};

export default MarkerInfo;
