import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
// Redux
import { Link, useHistory, useLocation } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import axios from "axios";
import { setCookie, getCookie } from "../../common/utils/Cookie";
import { Row, Col, CardBody, Card, Container } from "reactstrap";

// import images
import profile from "../../assets/images/login/landing.png";
import shuketlogo from "../../assets/images/login/logo.png";
import kakaoLogo from "../../assets/images/login/ico-kakao.png";
import googleLogo from "../../assets/images/login/ico-google.png";
import facebookLogo from "../../assets/images/login/ico-facebook-i.png";
import instagramLogo from "../../assets/images/login/ico-instagram-i.png";
import twitterLogo from "../../assets/images/login/ico-twitter-i.png";
import naverLogo from "../../assets/images/login/ico-naver.png";
import barLogo from "../../assets/images/login/icon-bar.png";
import { render } from "react-dom";
import "./Login.css";
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const REDIRECT_URI = process.env.REACT_APP_FRONT_KEY + "/kakao/login";

const Login = () => {
  let location = useLocation();
  console.log(location.pathname);
  const history = useHistory();
  location.pathname == "/login" ? (document.body.style = "background-color:#2a3042") : (document.body.style = "background-color:#FFFFFF");
  // 구글 로그인
  const handleGoogleLoginSucess = async googleData => {
    await axios
      .post(process.env.REACT_APP_APIKEY + "/api/auth/googlelogin", {
        token: googleData.tokenId,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async res => {
        console.log(res);
        let googleEmail = googleData.profileObj.email;
        let snsType = "google";
        let snsData = {
          userEmail: googleEmail,
          snsType: snsType,
          accessToken: res.data.xToken,
        };
        if (res.data.result == "wait") {
          history.push("/joinComplete");
        } else if (res.data.result == "success") {
          setCookie("xToken", res.data.xToken, {
            path: "/",
            maxAge: 24 * 60 * 60 * 7,
          });
          setCookie("snsType", snsType, {
            path: "/",
            maxAge: 24 * 60 * 60 * 7,
          });
          setCookie("userCode", JSON.stringify(res.data.data[0].USER_CODE), {
            path: "/",
            maxAge: 24 * 60 * 60 * 7,
          });
          const userCode = res.data.data[0].USER_CODE;
          history.push({
            pathname: "/mypage",
            state: { userData: snsData, userCode: userCode },
          });
        } else {
          history.push({
            pathname: "/userSelect",
            state: { userData: snsData },
          });
        }
      });
  };
  // 카카오 로그인 token + userData
  const handleKakaoLoginSucess = async kakaoData => {
    window.location.assign(`https://kauth.kakao.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`);
    history.push("/kakao/login");
  };
  // 네이버 로그인
  const initializeNaverLogin = () => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: process.env.REACT_APP_NAVER_KEY,
      callbackUrl: process.env.REACT_APP_NAVER_CALLBACK,
      isPopup: false,
      loginButton: { color: "green", type: 1, height: "47" },
    });
    naverLogin.init();
  };
  // 네이버 토큰 받기
  const getNaverToken = async () => {
    if (!location.hash) return;
    const token = location.hash.split("=")[1].split("&")[0];
    console.log("token", token);
    // 네이버 토큰을 받아 유저 정보 받아 오기
    await axios
      .post(process.env.REACT_APP_APIKEY + "/api/auth/naverToken", {
        token: token,
      })
      .then(async res => {
        console.log("결과", res);
        console.log("결과 ", res.data.result);

        let email = res.data.email;
        let xToken = res.data.xToken;
        let snsType = "naver";
        console.log("naver-email", email);
        console.log("데이터 성공인지?", res.data);

        if (res.data.result == "success") {
          console.log("tdfsf", res.data.data[0].USER_CODE);
          window.localStorage.setItem("userCode", JSON.stringify(res.data.data[0].USER_CODE));
          const userCode = res.data.data[0].USER_CODE;
          setCookie("xToken", xToken, {
            path: "/",
            maxAge: 24 * 60 * 60 * 7,
          });
          setCookie("snsType", snsType, {
            path: "/",
            maxAge: 24 * 60 * 60 * 7,
          });
          setCookie("userCode", JSON.stringify(res.data.data[0].USER_CODE), {
            path: "/",
            maxAge: 24 * 60 * 60 * 7,
          });
          history.push({ pathname: "/mypage", state: { userEmail: email, userCode: userCode } });
        } else if (res.result == "wait") {
          history.push("/joinComplete");
        } else {
          let snsData = {
            userEmail: email,
            snsType: "NAVER",
          };
          history.push({
            pathname: "/userSelect",
            state: { userData: snsData },
          });
        }
      });
  };

  useEffect(() => {
    initializeNaverLogin();
    getNaverToken();
  }, []);
  useEffect(() => {
    if (getCookie("xToken")) {
      history.push("/mypage");
    }
  }, []);
  const handleLoginFail = async googleData => {
    console.log(`error !`);
  };
  const notSupport = () => {
    alert("아직 지원하지 않습니다.");
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>OPS - shuket</title>
      </MetaTags>
      {/* <Container>
        <Row> */}
      <div className="login-box">
        <div className="logo">
          <img src={shuketlogo} />
        </div>
        <div className="title">OPS 전단자동화시스템</div>
        <div className="sub-title">Online Publishing System</div>
        <div className="social-icons">
          <div>
            <img src={kakaoLogo} alt="" height="47" onClick={() => handleKakaoLoginSucess()} />
          </div>
          <div id="naverIdLogin"></div>
          <div>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_KEY}
              render={({ onClick }) => (
                <img
                  onClick={e => {
                    e.preventDefault();
                    onClick();
                  }}
                  src={googleLogo}
                  alt=""
                  height="47"
                />
              )}
              onSuccess={handleGoogleLoginSucess}
              onFailure={handleLoginFail}
              cookiePolicy={"single_host_origin"}
            />
          </div>
          {/* <div>
            <img
              src={facebookLogo}
              alt=""
              onClick={e => {
                e.preventDefault();
                notSupport();
              }}
              height="47"
            />
          </div>

          <div>
            <img
              src={twitterLogo}
              alt=""
              onClick={e => {
                e.preventDefault();
                notSupport();
              }}
              height="47"
            />
          </div>

          <div>
            <img
              src={instagramLogo}
              alt=""
              onClick={e => {
                e.preventDefault();
                notSupport();
              }}
              height="47"
            />
          </div> */}
        </div>
        <div className="login-message">첫방문인 경우 로그인 과정에서 가입 절차를 진행합니다</div>
        <div className="landing">
          <img src={profile} alt="" />
        </div>
      </div>
      <div className="copyright">COPYRIGHT 2022- © shuket All RIGHTS RESERVED </div>
      {/* </Row>
      </Container> */}
    </React.Fragment>
  );
};

export default Login;
