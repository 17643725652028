import React, { useState, useEffect, useLayoutEffect } from "react";
import MetaTags from "react-meta-tags";
import axios from "axios";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PropTypes, { func } from "prop-types";
import { useQuery, useMutation, queryCache } from "react-query";

import { Container, Row, Col, Button, Card, CardBody, Input, Table, CardTitle, CardSubtitle } from "reactstrap";
import { getUserAPI, userRolesAPI } from "common/api/user";
import { getCookie } from "common/utils/Cookie";
function ChangeRoles() {
  const [selected, setSelected] = useState("");
  const [statusSelected, setStatusSelected] = useState("");

  const { data: userInfo } = useQuery("users", getUserAPI);
  const userCode = userInfo?.USER_CODE;
  const companyCode = userInfo?.COMPANY_CODE;

  const { data: dataLists } = useQuery(["userlist", userCode, companyCode], () => userRolesAPI({ USER_CODE: userCode, COMPANY_CODE: companyCode }), {
    enabled: !!userCode,
  });

  const updateBtn = USER_CODE => {
    if (selected) {
      axios.post(process.env.REACT_APP_APIKEY + `/api/users/updateStatus`, { USER_CODE: USER_CODE, USER_LEVEL_CODE: selected }).then(res => {
        if (res.data.result === "success") {
          console.log("수정", res);
          alert("레벨 수정 성공");
        } else if (res.data.result === "fail") {
          alert("레벨 수정 실패");
          return false;
        }
      });
    }
    if (statusSelected) {
      axios
        .post(
          process.env.REACT_APP_APIKEY + `/api/users/userRefusUpdate`,
          { USER_CODE: USER_CODE, STATUS: statusSelected },
          {
            headers: {
              Authorization: getCookie("xToken"),
              "Content-Type": "application/json;charset=UTF-8",
            },
          },
        )
        .then(res => {
          console.log(res);
          console.log(USER_CODE, statusSelected);
          if (res.data.result === "success") {
            console.log("수정", res);
            alert("상태 수정 성공");
          } else if (res.data.result === "fail") {
            alert("상태 수정 실패");
            return false;
          }
        });
    }
  };

  let optionGroup = [
    { id: "LV00000001", label: "관리자", value: "LV00000001" },
    { id: "LV00000002", label: "매니저", value: "LV00000002" },
    { id: "LV00000003", label: "스태프", value: "LV00000003" },
  ];
  let statusOptions = [
    { id: "W", label: "대기", value: "W" },
    { id: "A", label: "승인", value: "A" },
  ];

  var inquiries = optionGroup.filter(data => {
    if (selected === "LV00000001" && data.label.toLowerCase() === "LV00000002") {
      console.log("1", data);
      return data;
    } else if (selected === "LV00000002" && data.label.toLowerCase() === "LV00000002") {
      console.log("2", data);
      return data;
    } else if (selected === "LV00000003" && data.label.toLowerCase() === "LV00000003") {
      console.log("2", data);
      return data;
    }
  });
  var statusfilter = statusOptions.filter(data => {
    if (statusSelected === "W" && data.label.toLocaleLowerCase() === "W") {
      console.log("1 status", data);
      return data;
    } else if (statusSelected === "A" && data.label.toLocaleLowerCase() === "A") {
      console.log("a status", data);
      return data;
    }
  });

  const changeSelectOptionHandler = e => {
    setSelected(e.target.value);
  };
  const changeStatusOptionHandler = e => {
    setStatusSelected(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>SHUKET | ChangeRoles</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="UI Elements" breadcrumbItem="권한 변경 내역 리스트" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle>권한 변경</CardTitle>
                  <CardSubtitle className="mb-3">유저 리스트의 권한을 변경할 수 있습니다.</CardSubtitle>

                  <div>
                    <Table className="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>이름</th>
                          <th>이메일</th>
                          <th>전화번호</th>
                          <th>직함</th>
                          <th>상태</th>
                          <th>권한</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLists &&
                          dataLists.map((userList, index) => {
                            return (
                              <tr key={userList.USER_CODE}>
                                <th scope="row">{index + 1}</th>
                                <td>{userList.USER_NAME}</td>
                                <td>{userList.USER_EMAIL}</td>
                                <td>{userList.PHONE_NUM}</td>
                                <td>{userList.USER_POSITION}</td>
                                <td>
                                  <Input
                                    className="form-control"
                                    type="select"
                                    name={userList.USER_CODE}
                                    id={userList.USER_CODE}
                                    defaultValue={userList.STATUS}
                                    value={statusfilter.value}
                                    onChange={e => {
                                      changeStatusOptionHandler(e);
                                      console.log(statusfilter.values, userList.USER_CODE);
                                    }}
                                  >
                                    <option name="W" value="W">
                                      대기
                                    </option>
                                    <option name="A" value="A">
                                      활성화
                                    </option>
                                  </Input>
                                </td>
                                <td>
                                  <Input
                                    className="form-control"
                                    type="select"
                                    name={userList.USER_CODE}
                                    id={userList.USER_CODE}
                                    defaultValue={userList.USER_LEVEL_CODE}
                                    value={inquiries.value}
                                    onChange={e => {
                                      changeSelectOptionHandler(e);
                                      console.log(inquiries.values, userList.USER_CODE);
                                    }}
                                  >
                                    <option name="LV00000001" value="LV00000001">
                                      관리자
                                    </option>
                                    <option name="LV00000002" value="LV00000002">
                                      매니저
                                    </option>
                                    <option name="LV00000003" value="LV00000003">
                                      스태프
                                    </option>
                                  </Input>
                                </td>
                                <td>
                                  <Button onClick={() => updateBtn(userList.USER_CODE)}>수정</Button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                    {/* {isLoading ? <Spinner className="ms-2" color="warning" style={{ width: "50px", height: "50px", margin: "40px 50%" }} /> : ""} */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
ChangeRoles.propTypes = {
  options: PropTypes.array,
  defaultValue: PropTypes.string,
};

export default ChangeRoles;
