import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Card, CardBody, Nav, NavLink, TabContent, TabPane, Form, Button } from "reactstrap";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MetaTags from "react-meta-tags";
import axios from "axios";
import { useQuery, useMutation, queryCache } from "react-query";

//Import Components
import Accordian from "../Crypto/CryptoIcoLanding/Faqs/accordian";
const PAGE_SIZE = 2;

function Faq() {
  const [activeTab, setactiveTab] = useState("1");
  const [allFaqDatas, setAllFaqDatas] = useState([]);
  const [faq2Datas, setFaq2Datas] = useState([]);
  const [faq3Datas, setFaq3Datas] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  // 수정필) url에 임의 데이터를 주었음, db명이 확정된 이후 category를 url에 입력해야 함
  const [category, setCategory] = useState("");

  const searchBtn = () => {
    axios.post(process.env.REACT_APP_APIKEY + "/api/faq/list", { QUESTION: searchInput }).then(res => {
      console.log(res.data.data);
      setAllFaqDatas(res.data.data);
      setactiveTab("1");
    });
  };

  // 전체검색
  const allSearch = useCallback(async () => {
    await axios.post(process.env.REACT_APP_APIKEY + "/api/faq/list").then(res => {
      console.log(res.data.data);
      setAllFaqDatas(res.data.data);
    });
  }, [allFaqDatas]);

  // 두번째 탭 검색
  const faq1Search = useCallback(async () => {
    await axios.get(process.env.REACT_APP_APIKEY + `/api/faq/category?FAQ_CATEGORY=deliver`).then(res => {
      console.log(res.data.data);
      setFaq2Datas(res.data.data);
    });
  }, [allFaqDatas]);
  // 세번째 탭 검색
  const faq2Search = useCallback(async () => {
    await axios.get(process.env.REACT_APP_APIKEY + `/api/faq/category?FAQ_CATEGORY=Refund`).then(res => {
      console.log(res.data.data);
      setFaq3Datas(res.data.data);
    });
  }, [allFaqDatas]);

  useEffect(async () => {
    allSearch();
    faq1Search();
    faq2Search();
  }, []);

  return (
    <React.Fragment>
      <section className="section" id="faqs">
        <MetaTags>
          <title>SHUKET | FAQs</title>
        </MetaTags>
        <Container>
          <Breadcrumbs title="UI Elements" breadcrumbItem="권한 변경 내역 리스트" />
          <Row style={{ marginBottom: "25px" }}>
            <Form className="row row-cols-lg-auto g-3 align-items-center ">
              <Col xl={2}>
                <label htmlFor="example-text-input" className="col-md-2 col-form-label">
                  검색
                </label>
              </Col>
              <Col xl={8}>
                <div className="col-md-9">
                  <input className="form-control" type="text" value={searchInput || ""} onChange={e => setSearchInput(e.target.value)} />
                </div>
              </Col>
              <Col xl={2}>
                <Button color="success" className="btn btn-success " onClick={() => searchBtn()}>
                  Button
                </Button>
              </Col>
            </Form>
          </Row>

          <Row>
            <Col lg="12">
              <div className="vertical-nav">
                <Row>
                  <Col lg="2" sm="4">
                    <Nav pills className="flex-column">
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          setactiveTab("1");
                        }}
                      >
                        <i className="bx bx-help-circle nav-icon d-block mb-2" />
                        <p className="font-weight-bold mb-0">전체</p>
                      </NavLink>

                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          setactiveTab("2");
                          setCategory("배송");
                        }}
                        onChange={() => {
                          setCategory("배송");
                        }}
                      >
                        <i className="bx bx-receipt nav-icon d-block mb-2" />
                        <p className="font-weight-bold mb-0">배송</p>
                      </NavLink>

                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          setactiveTab("3");
                          setCategory("환불");
                        }}
                        onChange={() => {
                          setCategory("환불");
                        }}
                      >
                        <i className="bx bx-timer d-block nav-icon mb-2" />
                        <p className="font-weight-bold mb-0">환불</p>
                      </NavLink>
                    </Nav>
                  </Col>
                  <Col lg="10" sm="8">
                    <Card>
                      <CardBody>
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="1" id="buy">
                            <h4 className="card-title mb-4">전체</h4>

                            <Accordion defaultActiveKey="0">
                              {allFaqDatas &&
                                allFaqDatas.map((allFaqData, i) => {
                                  return (
                                    <Accordion.Item eventKey={i} key={allFaqData.FAQ_CODE}>
                                      <Accordion.Header>{allFaqData.QUESTION}</Accordion.Header>
                                      <Accordion.Body>{allFaqData.ANSWER}</Accordion.Body>
                                    </Accordion.Item>
                                  );
                                })}
                            </Accordion>
                          </TabPane>

                          <TabPane tabId="2">
                            <h4 className="card-title mb-4">배송</h4>

                            <Accordion defaultActiveKey="0">
                              {faq2Datas
                                ? faq2Datas.map((faqData, i) => {
                                    return (
                                      <Accordion.Item eventKey={i} key={faqData.FAQ_CODE}>
                                        <Accordion.Header>{faqData.QUESTION}</Accordion.Header>
                                        <Accordion.Body>{faqData.ANSWER}</Accordion.Body>
                                      </Accordion.Item>
                                    );
                                  })
                                : ""}
                            </Accordion>
                          </TabPane>

                          <TabPane tabId="3">
                            <h4 className="card-title mb-4">환불</h4>

                            <Accordion defaultActiveKey="0">
                              {faq3Datas &&
                                faq3Datas.map((faqData, i) => {
                                  return (
                                    <Accordion.Item eventKey={i} key={faqData.FAQ_CODE}>
                                      <Accordion.Header>{faqData.QUESTION}</Accordion.Header>
                                      <Accordion.Body>{faqData.ANSWER}</Accordion.Body>
                                    </Accordion.Item>
                                  );
                                })}
                            </Accordion>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Faq;
