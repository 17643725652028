import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import axios from "axios";
import { getCookie, removeCookie } from "../../common/utils/Cookie";
// Redux
import { useLocation, useHistory } from "react-router-dom";

import { Row, Col, CardBody, Card, Container } from "reactstrap";

const UserSelect = props => {
  const history = useHistory();
  const location = useLocation();
  const userData = location.state.userData;
  document.body.style = "background-color:#FFFFFF";
  useEffect(() => {
    console.log("이메일 확인 userData  ", userData);
    console.log(`location.state.checkOutItems -> ${JSON.stringify(location.state.userData)}`);
  }, []);

  const inviteBtn = async () => {
    await axios.get(process.env.REACT_APP_APIKEY + `/api/signup/inviteEmail?email=${userData.userEmail}`).then(res => {
      console.log("111", res.data);
      if (res.data.result === "success") {
        history.push({
          pathname: "/loginTab/tabContent",
          state: { userData: userData },
        });
      } else {
        alert("초대 메일이 발송된 계정이 아닙니다.");
      }
    });
  };

  const businessBtn = () => {
    console.log("확인이다 ---", userData.userEmail);
    history.push({
      pathname: "/loginTab/tabBusiness",
      state: { userData: userData },
    });
  };

  const logoutBtn = async props => {
    console.log("props -- ", userData.snsType);
    // 카카오
    if (userData.snsType === "KAKAO") {
      axios.post(process.env.REACT_APP_APIKEY + "/api/auth/kakaoLogout", { token: `${getCookie("xToken")}` }).then(res => {
        removeCookie("xToken");
        console.log("카카오 로그아웃", res);
        alert("카카오 로그아웃 성공");
        history.push("/login");
      });
      // 구글
    } else if (userData.snsType === "GOOGLE") {
      axios.post(process.env.REACT_APP_APIKEY + "/api/auth/googleLogout", { token: `${getCookie("xToken")}` }).then(res => {
        console.log("11", res);
        console.log("22", res.data);
        if (res.data.result === "OK") {
          alert("로그 아웃 되었습니다.");
          removeCookie("xToken");
          history.push("/login");
        } else {
          alert("로그 아웃 실패");
        }
      });
      // 네이버
    } else if (userData.snsType === "NAVER") {
      alert("네이버 로그아웃");
      removeCookie("xToken");
      history.push("/login");
    }
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>가입자 선택</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <p onClick={() => logoutBtn()} style={{ cursor: "pointer" }}>
          로그아웃
        </p>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={8} xl={7}>
              <Card className="overflow-hidden">
                <CardBody>
                  {/* <Link to="/loginTab"> */}
                  <div
                    onClick={() => businessBtn()}
                    className="mb-4 max-auto"
                    style={{
                      float: "left",
                      height: "250px",
                      width: "49%",
                      backgroundColor: "#041562",
                      textAlign: "center",
                      color: "white",
                      fontSize: "30px",
                      paddingTop: "100px",
                      cursor: "pointer",
                    }}
                  >
                    사업자 가입
                  </div>
                  {/* </Link> */}
                  <div
                    onClick={() => inviteBtn()}
                    className="mb-4 max-auto"
                    style={{
                      float: "right",
                      height: "250px",
                      width: "49%",
                      backgroundColor: "#11468F",
                      textAlign: "center",
                      color: "white",
                      fontSize: "30px",
                      paddingTop: "100px",
                      cursor: "pointer",
                    }}
                  >
                    초대직원 가입
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserSelect;
