import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import ChatComment from "./ChatComment";
import { Button, Modal, UncontrolledTooltip, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import "react-perfect-scrollbar/dist/css/styles.css";

function EffectMarker({ markers, markerDates, seq, infoGet, userInfo }) {
  const textRef = useRef();
  // console.log("확인 ++++++++++++++++", markers);
  // console.log("확인 ++++++++++++++++", markerDates);
  const [xCheck, setXCheck] = useState("");
  const [yCheck, setYCheck] = useState("");
  const [commentLists, setCommentLists] = useState([]);
  const [xpoint, setXpoint] = useState("");
  const [ypoint, setYpoint] = useState("");
  const [commentValue, setCommentValue] = useState("");
  const [isComment, setIsComment] = useState(false);
  const [commentMessage, setCommentMessage] = useState("");
  const [inputs, setInputs] = useState([{ uploadFile: null, fileName: "", COMMENT_IMG: null }]);
  const { uploadFile, fileName, COMMENT_IMG } = inputs;

  // 모달
  const [getModal, setGetModal] = useState(false);
  const getModalToggle = () => setGetModal(!getModal);
  // 이모티콘 모달
  const [emojiModal, setEmojiModal] = useState(false);
  const emojiToggle = () => setEmojiModal(!emojiModal);
  // 이미지 업로드 모달
  const [imgModal, setImgModal] = useState(false);
  const imgToggle = () => setImgModal(!imgModal);

  const modalOpen = () => {
    getModalToggle();
  };

  const emojiStyle = {
    position: "absolute",
    height: "350px",
    zIndex: 1000,
    left: xpoint <= 1200 ? `${xpoint + 250}px` : null,
    // top: 0,
    // bottom:'10px',
    // left: '154px',
    // bottom: testy <= 500 ? '326px' : 850 - `${testy}`,
    transition: "0.3s",
    bottom: "5px",
  };
  // emoji-mart
  const addEmoji = e => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach(el => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setCommentValue(CommentValue + emoji);
  };
  // 모달 위치를 위한 스타일
  const customStyles = {
    position: "absolute",
    maxHeight: "460px",
    minHeight: "230px",
    left: xpoint <= 1200 ? `${xpoint + 250}px` : null,
    right: xpoint <= 1200 ? null : 1710 - `${xpoint}`,
    top: ypoint <= 500 ? `${ypoint}px` : null,
    bottom: ypoint <= 500 ? null : 850 - `${ypoint}`,
    width: "100%",
    transition: "all 0.3s",
  };
  const OpenStyles = {
    position: "absolute",
    height: "300px",
    left: xpoint <= 1200 ? `${xpoint + 250}px ` : null,
    right: xpoint <= 1200 ? null : 1710 - `${xpoint}`,
    bottom: ypoint <= 500 ? "350px" : "350px",
    bottom: "350px",
    width: "100%",
    transition: "all 0.3s",
    // zIndex:'-10',
  };
  // 댓글 추가하기
  const addMessage = () => {
    if (!isComment) {
      setCommentMessage("댓글을 입력하여 주세요.");
      console.log("댓글 입력안해 추가 안됨");
      return false;
    } else {
      axios
        .post(process.env.REACT_APP_APIKEY + `/api/comment/create`, {
          PROJECT_SNAPSHOT_CODE: infoGet.PROJECT_SNAPSHOT_CODE,
          COMMENT_TEXT: commentValue,
          COMMENT_XPOINT: xCheck,
          COMMENT_YPOINT: yCheck,
          PROJECT_CODE: seq,
          USER_CODE: userInfo?.USER_CODE,
          C_ID: userInfo?.USER_CODE,
          M_ID: userInfo?.USER_CODE,
          COMPANY_CODE: userInfo?.COMPANY_CODE,
        })
        .then(res => {
          const comment = {
            COMMENT_TEXT: commentValue,
            USER_CODE: userInfo?.USER_CODE,
          };
          if (res.data.result === "success") {
            console.log(res);
            setCommentLists(commentLists.concat(comment));
            setCommentValue("");
          } else {
            console.log("에러남 ;;");
          }
        });
    }
  };
  // 댓글 value OnChange
  const commentOnChange = e => {
    setCommentValue(e.target.value);
    if (e.target.value.length < 1) {
      console.log("댓글 입력하세요");
      setCommentMessage("댓글을 입력하여 주세요.");
      setIsComment(false);
    } else {
      console.log("댓글 성공");
      setCommentMessage("");
      setIsComment(true);
    }
  };
  // 마커
  const pointClickBtn = async (COMMENT_XPOINT, COMMENT_YPOINT) => {
    await axios
      .get(process.env.REACT_APP_APIKEY + `/api/comment/xycommentList?PROJECT_CODE=${seq}&COMMENT_XPOINT=${COMMENT_XPOINT}&COMMENT_YPOINT=${COMMENT_YPOINT}`)
      .then(res => {
        console.log("값 확인", seq, COMMENT_XPOINT, COMMENT_YPOINT);
        setCommentLists(res.data.data);
        setXCheck(COMMENT_XPOINT);
        setYCheck(COMMENT_YPOINT);
        setXpoint(COMMENT_XPOINT);
        setYpoint(COMMENT_YPOINT);

        return res.data.data;
      });
  };

  // 댓글 삭제
  const removeComment = COMMENT_CODE => {
    if (window.confirm("댓글을 삭제하시겠습니까?")) {
      axios.post(process.env.REACT_APP_APIKEY + `/api/comment/commentRemove`, { COMMENT_CODE: COMMENT_CODE }).then(res => {
        if (res.data.result === "success") {
          console.log("댓글 삭제 결과", res);
          setCommentLists(commentLists.filter(commentList => commentList.COMMENT_CODE !== COMMENT_CODE));
        } else if (res.data.result === "fail") {
          alert("댓글 삭제 실패");
          return false;
        }
      });
    }
  };
  // textarea 높이 자동 조절
  const resizeHeight = useCallback(() => {
    textRef.current.style.height = textRef.current.scrollHeight + "px";
  }, []);
  // Enter , Shift + Enter 구분하기
  const updateKeyPress = e => {
    if (e.keyCode === 13 && e.shiftKey) {
      setCommentValue(commentValue);
    } else if (e.key === "Enter") {
      addMessage();
      setCommentValue("");
    }
  };

  const imageClose = () => {
    imgToggle();
    setInputs("");
  };
  // file OnChange
  function handleFileChange(e) {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: e.target.files[0],
      [fileName]: value,
    });
  }
  // image upload api
  function addImage() {
    const url = process.env.REACT_APP_APIKEY + `/api/upload/uploadSingle`;
    const formData = new FormData();
    formData.append("location", "comment");
    formData.append("unique", true);
    formData.append("uploadfile", inputs.uploadFile);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  }
  // comment image insert api
  function handleFormSubmit() {
    addImage().then(res => {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      axios
        .post(
          process.env.REACT_APP_APIKEY + `/api/comment/imgUpload`,
          {
            PROJECT_SNAPSHOT_CODE: infoGet.PROJECT_SNAPSHOT_CODE,
            COMMENT_IMG: "PDSdata/uploads/comment/" + res.data.data.filename,
            COMMENT_XPOINT: xCheck,
            COMMENT_YPOINT: yCheck,
            PROJECT_CODE: seq,
            USER_CODE: userInfo?.USER_CODE,
            COMPANY_CODE: userInfo?.COMPANY_CODE,
            C_ID: userInfo?.USER_CODE,
            M_ID: userInfo?.USER_CODE,
          },
          config,
        )
        .then(res => {
          const comment = {
            COMMENT_IMG: res.data.dataImg?.COMMENT_IMG,
            USER_CODE: userInfo?.USER_CODE,
            COMMENT_CODE: res.data.data,
          };
          setCommentLists(commentLists.concat(comment));
          setInputs(res.data);
          imageClose();
        })
        .catch(err => console.log(err));
      setInputs({
        uploadFile: null,
        location: "comment",
      });
    });
  }
  // useEffect(() => {
  //   if (!getModal) {
  //     document.body.style.overflow = "unset";
  //   } else {
  //     document.body.style.overflow = "hidden";
  //   }
  // }, [getModal]);

  return (
    <>
      {markers &&
        markers
          .filter(marker => marker.PROJECT_SNAPSHOT_ORDER === infoGet.PROJECT_SNAPSHOT_ORDER)
          .map((marker, index) => (
            <>
              {marker.COMMENT_XPOINT === null || marker.COMMENT_YPOINT === null || marker.PROJECT_SNAPSHOT_URL === null ? null : (
                <div
                  key={marker.PROJECT_SNAPSHOT_SET}
                  style={{
                    top: `${marker.COMMENT_YPOINT}px`,
                    left: `${marker.COMMENT_XPOINT}px`,
                  }}
                  className="custom-marker"
                  // className={markerDates.some(markerDate => markerDate.COMMENT_XPOINT === marker.COMMENT_XPOINT) ? "custom-marker-date" : "custom-marker"}
                  // className={markerDates.includes(COMMENT_XPOINT) === marker.COMMENT_XPOINT ? "custom-marker" : "custom-marker-date"}
                  comment_xpoint={marker.COMMENT_XPOINT}
                  comment_ypoint={marker.COMMENT_YPOINT}
                  projectsnapshoturl={marker.PROJECT_SNAPSHOT_URL}
                  onClick={e => {
                    pointClickBtn(
                      marker.COMMENT_XPOINT,
                      marker.COMMENT_YPOINT,
                      marker.COMMENT_CODE,
                      marker.PROJECT_SNAPSHOT_CODE,
                      infoGet.PROJECT_SNAPSHOT_SET,
                      infoGet.PROJECT_SNAPSHOT_ORDER,
                    );
                    setXpoint(e.nativeEvent.clientX - 15);
                    setYpoint(e.nativeEvent.clientY);
                    modalOpen();
                  }}
                >
                  {marker.NO}
                </div>
              )}
            </>
          ))}
      {/* 모달 가져오기 */}
      <Modal
        isOpen={getModal}
        toggle={getModalToggle}
        autoFocus={false}
        size="ml"
        scrollable={true}
        style={emojiModal === true ? OpenStyles : customStyles}
        zIndex={10}
      >
        <ModalHeader toggle={getModalToggle}></ModalHeader>
        <ModalBody style={{ overflowY: "hidden" }}>
          <ChatComment
            commentLists={commentLists}
            seq={seq}
            userInfo={userInfo}
            infoGet={infoGet}
            commentValue={commentValue}
            xCheck={xCheck}
            yCheck={yCheck}
            removeComment={removeComment}
            setCommentLists={setCommentLists}
          />
        </ModalBody>
        <ModalFooter style={{ border: "none" }}>
          <div className="p-3 chat-input-section" style={{ width: "100%" }}>
            <Row>
              <Col>
                <div className="position-relative">
                  <textarea
                    autoFocus={true}
                    style={{ overflow: "hidden", resize: "none" }}
                    ref={textRef}
                    type="text"
                    name="commentValue"
                    value={commentValue}
                    onKeyPress={updateKeyPress}
                    onChange={commentOnChange}
                    onInput={resizeHeight}
                    className="form-control chat-input"
                    placeholder="댓글을 입력하세요..."
                  />
                  {commentValue.length < 1 && <span className={`message ${isComment ? "success" : "error"}`}>{commentMessage}</span>}
                  <div className="chat-input-links">
                    <ul className="list-inline mb-0">
                      {/* <li className="list-inline-item" style={{ cursor: "pointer" }}>
                        <i className="mdi mdi-emoticon-happy-outline" id="Emojitooltip" onClick={emojiToggle} />
                        <UncontrolledTooltip placement="top" target="Emojitooltip">
                          Emojis
                        </UncontrolledTooltip>
                      </li> */}
                      <li
                        className="list-inline-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setImgModal(true);
                        }}
                      >
                        <i className="mdi mdi-file-image-outline" id="Imagetooltip" style={{ cursor: "pointer" }} />
                        <UncontrolledTooltip placement="top" target="Imagetooltip">
                          이미지 올리기
                        </UncontrolledTooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col className="col-auto">
                <Button type="button" color="primary" onClick={() => addMessage()} className="btn btn-primary btn-rounded chat-send w-md ">
                  <span className="d-none d-sm-inline-block me-2">Send</span> <i className="mdi mdi-send" />
                </Button>
              </Col>
            </Row>
          </div>
        </ModalFooter>
      </Modal>

      {/* <Modal isOpen={emojiModal} toggle={emojiToggle} style={emojiStyle}>
        <div>
          <Picker onSelect={addEmoji} autoFocus={true} style={{ width: "498px", height: "350px" }} />
        </div>
      </Modal> */}
      <Modal isOpen={imgModal} toggle={imgToggle} backdrop={false}>
        <ModalHeader toggle={imgToggle}></ModalHeader>
        <ModalBody>
          <input accept="image/*" id="raised-button-file" name="uploadFile" type="file" file={uploadFile} value={fileName} onChange={handleFileChange} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleFormSubmit}>
            업로드
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

EffectMarker.propTypes = {
  markers: PropTypes.array,
  markerDates: PropTypes.array,
  seq: PropTypes.string,
  infoGet: PropTypes.object,
  userInfo: PropTypes.object,
};

export default EffectMarker;
