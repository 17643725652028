import usFlag from "../assets/images/flags/us.jpg";
import krFlag from "../assets/images/flags/korea.jpg";
import spain from "../assets/images/flags/spain.jpg";
import germany from "../assets/images/flags/germany.jpg";
import italy from "../assets/images/flags/italy.jpg";
import russia from "../assets/images/flags/russia.jpg";

const languages = {
  kr: {
    label: "Korea",
    flag: krFlag,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
};

export default languages;
