import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty, map } from "lodash";
import { Col, Row } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { getCookie } from "../../common/utils/Cookie";
import CommentItem from "./CommentItem";

const ChatComment = ({ props, commentLists, userInfo, setCommentLists, removeComment }) => {
  let myToken = getCookie("xToken");

  const [messageBox, setMessageBox] = useState(null);

  useEffect(() => {
    if (!isEmpty(commentLists)) scrollToBottom();
  }, [commentLists]);

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 100;
      console.log(messageBox.scrollTop, messageBox.scrollHeight);
    }
  };

  return (
    <React.Fragment>
      <PerfectScrollbar style={{ height: "291px" }} containerRef={ref => setMessageBox(ref)}>
        <Row>
          <Col lg="12">
            <div className="d-lg-flex">
              <div className="w-100 user-chat">
                <div className="chat-conversation p-3">
                  <ul className="list-unstyled">
                    {commentLists &&
                      map(commentLists, message => (
                        <CommentItem
                          key={message.COMMENT_CODE}
                          userInfo={userInfo}
                          message={message}
                          commentLists={commentLists}
                          setCommentLists={setCommentLists}
                          removeComment={removeComment}
                        />
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </PerfectScrollbar>
    </React.Fragment>
  );
};

ChatComment.propTypes = {
  commentLists: PropTypes.array,
  props: PropTypes.any,
  userInfo: PropTypes.object,
  removeComment: PropTypes.func,
  commentValue: PropTypes.string,
  setCommentLists: PropTypes.func,
};

export default ChatComment;
