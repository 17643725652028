import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Input } from "reactstrap";
import axios from "axios";
import { useQuery } from "react-query";
import InputMask from "react-input-mask";

import Timer from "../../components/Auth/authTimer";

const TabContact = props => {
  const history = useHistory();
  const location = useLocation();
  const userData = location.state.userData;
  const [userType, setUserType] = useState("");

  const { status, data, error, isFetching } = useQuery("invite", async () => {
    const res = await axios.post(process.env.REACT_APP_APIKEY + "/api/signup/emailCode", { userEmail: userData?.userEmail });
    setUserType(res.data.data?.USER_TYPE);
    // console.log(res.data.data.FK_COMPANY_CODE);
    setCompanyCode(res.data.data?.FK_COMPANY_CODE);
    console.log("company Code : " + companyCode);
    return res.data.data?.FK_COMPANY_CODE;
  });

  useEffect(() => {
    console.log("ddffffff -- ", userData?.userEmail);
  }, []);

  const phoneNumChange = e => {
    setPhoneNum(e.target.value);
  };
  const userNameChange = event => {
    setUserName(event.target.value);
    if (event.target.value.length < 1 || event.target.value.length > 6) {
      setUserNameMessage("1글자 이상 6글자 미만으로 입력해주세요.");
      setIsUserName(false);
    } else {
      setUserNameMessage("올바른 이름 형식입니다 :)");
      setIsUserName(true);
    }
  };

  const verifyNumberChange = event => {
    setVerifyNumber(event.target.value);
  };

  const positionChange = event => {
    setPosition(event.target.value);
    if (event.target.value.length < 2) {
      setPositionMessage("직책을 입력해주세요..");
      setIsPosition(false);
    } else {
      setPositionMessage("올바른 직책 입니다 :)");
      setIsPosition(true);
    }
  };

  // const company = location.state.company
  const [userName, setUserName] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [isOpenPost, setIsOpenPost] = useState(false);
  const [verifyNumber, setVerifyNumber] = useState("");
  const [position, setPosition] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [addPhoneNum, setAddPhoneNum] = useState("");

  const [userNameMessage, setUserNameMessage] = useState("");
  const [phoneNumMessage, setPhoneNumMessage] = useState("");
  const [positionMessage, setPositionMessage] = useState("");

  const [isUserName, setIsUserName] = useState(false);
  const [isPhoneNum, setIsPhoneNum] = useState(false);
  const [isPosition, setIsPosition] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  // const [isUserEmail] = useState(true)

  function regExp(str) {
    var reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
    //특수문자 검증
    if (reg.test(str)) {
      //특수문자 제거후 리턴
      return str.replace(reg, "");
    }
  }
  const verifyNumberClick = () => {
    let phoneNumberCheck = regExp(phoneNum);
    const authSms = axios
      .post(process.env.REACT_APP_APIKEY + "/api/sms/verify", {
        verifyNumber: verifyNumber,
        phoneNumber: phoneNumberCheck,
      })
      .then(result => {
        // console.log(result)
        if (result.data.result == "success") {
          alert("문자인증에 성공하였습니다.");
          setIsVerify(true);
          onChangeOpenPost();
        } else {
        } //추가 알림창 작성예정
      });
  };
  const checkPhone = () => {
    let phoneNumber = regExp(phoneNum);
    console.log(process.env.REACT_APP_APIKEY + "/api/sms");
    const authSms = axios
      .post(process.env.REACT_APP_APIKEY + "/api/sms", {
        phoneNumber: phoneNumber,
      })
      .then(result => {
        if (result.data.result == "success") {
          setIsPhoneNum(true);
          onChangeOpenPost();
        }
      });
  };

  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
  };

  const requiredStyle = {
    color: "red",
    float: "right",
  };

  const createUserBtn = () => {
    isUserName, isPhoneNum, isPosition, isVerify;
    if (!isUserName) {
      alert("이름을 입력해 주세요..");
      return false;
    } else if (!isPhoneNum) {
      alert("전화번호를 입력해 주세요.");
      return false;
    } else if (!isPosition) {
      alert("직책을 입력해 주세요.");
      return false;
    } else if (!isVerify) {
      alert("전화번호를 인증해 주세요.");
      return false;
    } else {
      axios
        .post(process.env.REACT_APP_APIKEY + "/api/signup/createUser", {
          userName: userName,
          userEmail: userData?.userEmail,
          phoneNum: phoneNum,
          position: position,
          snsType: userData?.snsType,
          companyCode: companyCode,
          addPhoneNum: addPhoneNum,
        })
        .then(res => {
          console.log("추가완료", res);
          alert("회원가입에 성공하셨습니다.");
          history.push("/joinComplete");
        })
        .catch(error => {
          alert("계정 생성에 실패하셨습니다.");
        });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Form Layouts | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">연락처 정보 입력</CardTitle>

                  <div className="row mb-2">
                    <Label htmlFor="horizontal-firstname-Input" className="col-sm-3 col-form-label">
                      사용자명
                      <em className="required" style={requiredStyle}>
                        필수
                      </em>
                    </Label>
                    <Col sm={4}>
                      <Input
                        defaultValue={userName || ""}
                        onChange={userNameChange}
                        type="text"
                        className="form-control"
                        id="horizontal-name-Input"
                        placeholder="사용자명을 입력하세요."
                      />
                      {userName.length > 0 && <span className={`message ${isUserName ? "success" : "error"}`}>{userNameMessage}</span>}
                    </Col>
                  </div>

                  <div className="row mb-4">
                    <Label htmlFor="horizontal-firstname-Input" className="col-sm-3 col-form-label">
                      직책
                      <em className="required" style={requiredStyle}>
                        필수
                      </em>
                    </Label>
                    <Col sm={4}>
                      <Input type="text" className="form-control" placeholder="직책을 입력하세요." defaultValue={position || ""} onChange={positionChange} />
                      {position.length > 0 && <span className={`message ${isPosition ? "success" : "error"}`}>{positionMessage}</span>}
                    </Col>
                  </div>

                  <div className="row mb-4">
                    <Label htmlFor="horizontal-email-Input" className="col-sm-3 col-form-label">
                      이메일
                      <em className="required" style={requiredStyle}>
                        필수
                      </em>
                    </Label>
                    <Col sm={7}>
                      <Input defaultValue={userData?.userEmail} disabled type="email" className="form-control" id="horizontal-email-Input" />
                    </Col>
                  </div>

                  <div className="row mb-4">
                    <Label htmlFor="horizontal-email-Input" className="col-sm-3 col-form-label">
                      연락처1
                      <em className="required" style={requiredStyle}>
                        필수
                      </em>
                    </Label>
                    <Col sm={7}>
                      {/* <ISBN1 /> */}
                      <InputMask mask="999-9999-9999" defaultValue={phoneNum || ""} className="form-control input-color" onChange={phoneNumChange}></InputMask>
                    </Col>
                    <Col sm={2}>
                      <Button color="primary" className="w-md" onClick={checkPhone}>
                        인증
                      </Button>
                    </Col>
                  </div>
                  {isOpenPost ? (
                    <div className="row mb-4">
                      <Label htmlFor="horizontal-email-Input" className="col-sm-3 col-form-label">
                        인증번호
                      </Label>
                      <Col sm={2}>
                        <Input defaultValue={verifyNumber || ""} onChange={verifyNumberChange}></Input>
                      </Col>
                      <Col sm={1}>
                        남은시간 <Timer />
                      </Col>
                      <Col sm={2}>
                        <Button color="primary" className="w-md" onClick={verifyNumberClick}>
                          인증
                        </Button>
                      </Col>
                    </div>
                  ) : null}
                  <div className="row mb-4">
                    <Label htmlFor="horizontal-email-Input" className="col-sm-3 col-form-label">
                      연락처2
                    </Label>
                    <Col sm={7}>
                      <Input type="text" className="form-control" placeholder="" value={addPhoneNum} onChange={e => setAddPhoneNum(e.target.value)} />
                    </Col>
                  </div>

                  <div className="row justify-content-end">
                    <Col sm={9}>
                      <div>
                        <Button onClick={() => createUserBtn()} type="submit" color="primary" className="w-md">
                          다음
                        </Button>
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

TabContact.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  location: PropTypes.string,
  props: PropTypes.string,
  datas: PropTypes.string,
};

export default TabContact;
