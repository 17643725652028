import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// ydh 추가 AUTH 폴더
import Chat from "../pages/Chat/Chat";
import Login from "../pages/Auth/Login";
import InputTab from "pages/Auth/InputTab";
import UserSelect from "pages/Auth/UserSelect";
import Registration from "pages/Auth/Registration";
import Profile from "pages/Auth/UserProfile";
import kakaoUser from "common/action/kakaoUser";
import KakaoLogin from "pages/Auth/KakaoLogin";
import UserSignUp from "pages/Auth/UserSignUp";

import TermsTab from "../pages/Terms/TermsTab";
// ydh 추가 MYPAGE
import MyPage from "pages/MyPage/MyPage";

import InviteUser from "pages/InviteUser/InviteUser";
import ChangeRoles from "../pages/UserList/ChangeRoles.js";
import DelectUser from "pages/UserList/DelectUser";
import BlockUser from "pages/UserList/BlockUser";
import QnaBoard from "pages/QnaBoard/QnaBoard";
import QnaBoardDetail from "pages/QnaBoard/QnaBoardDetail";

// 코멘트
import CommentIndex from "pages/Comment";
import MarkerInfo from "components/Comment/MarkerInfo";

import TemplateUpload from "pages/TemplateUpload/TemplateUpload";
import Faq from "pages/Support/Faq";
//Invoices
import InvoicesList from "../pages/Invoices/invoices-list";
import InvoiceDetail from "../pages/Invoices/invoices-detail";

// Authentication related pages
// import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/mypage", component: MyPage },
  { path: "/inviteuser", component: InviteUser },
  { path: "/profile", component: Profile },
  { path: "/chat", component: Chat },
  { path: "/changerole", component: ChangeRoles },
  { path: "/delectuser", component: DelectUser },
  { path: "/blockuser", component: BlockUser },
  { path: "/faq", component: Faq },
  { path: "/comment", component: CommentIndex },
  { path: "/markerinfo/:seq", component: MarkerInfo },
  { path: "/qnaboard/:pageno", component: QnaBoard },
  { path: "/qnadetail", component: QnaBoardDetail },
  { path: "/templateUpload", component: TemplateUpload },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
  // { path: "/qnaboard", component: QnaBoard },
  // { path: "/qnaboard/detail", component: QnaBoardDetail },
];

const publicRoutes = [
  // ydh
  // 로그인 페이지

  { path: "/login", component: Login },
  // 로그인 유저 입력 페이지
  // { path: "/loginTab", component: inputTab },
  { path: "/kakao/login", component: KakaoLogin },
  { path: "/loginTab/:active_tab?", component: InputTab },
  { path: "/termsTab/:active_tab?", component: TermsTab },
  // 로그인 유저 유형 선택
  { path: "/userSelect", component: UserSelect },
  // 가입신청 완료
  { path: "/joinComplete", component: Registration },
  { path: "/userSignUp", component: UserSignUp },

  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
];

export { authProtectedRoutes, publicRoutes };
