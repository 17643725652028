import axios from "axios";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Row, CardTitle, Container, Label, Input, Button } from "reactstrap";
import { useQuery } from "react-query";
import { getCookie } from "../../common/utils/Cookie";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

function InviteUser() {
  const [email, setEmail] = useState("");
  const [userData, setUserData] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");

  const isEmailReg = email => {
    const emailRegex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    return emailRegex.test(email);
  };
  let myUserCode = getCookie("userCode");
  let myToken = getCookie("xToken");
  const { status, data, error, isFething } = useQuery("posts", async () => {
    await axios
      .post(
        process.env.REACT_APP_APIKEY + `/api/users/get`,
        { USER_CODE: myUserCode },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
          },
        },
      )
      .then(user => {
        setUserData(user.data.totalData);
      });
  });

  const changeEmail = e => {
    setEmail(e.target.value);
    if (e.target.value == undefined) {
      setEmailMessage("이메일을 입력해 주세요.");
      setIsEmail(false);
    } else {
      if (isEmailReg(email)) {
        setEmailMessage("입력 완료. :)");
        setIsEmail(true);
      } else {
        setEmailMessage("이메일 형식으로 입력해주세요.");
        setIsEmail(false);
      }
    }
  };
  const inviteEmail = async () => {
    if (userData.USER_LEVEL_CODE != "LV00000001") {
      alert("초대는 관리자만 가능합니다.");
      return false;
    }
    if (isEmail) {
      axios
        .post(
          process.env.REACT_APP_APIKEY + "/api/signup/addInvite",
          {
            USER_CODE: userData.USER_CODE,
            COMPANY_CODE: userData.COMPANY_CODE,
            USER_EMAIL: email,
          },
          {
            headers: {
              Authorization: myToken,
              "Content-Type": "application/json;charset=UTF-8",
            },
            responseType: "json",
          },
        )
        .then(async res => {
          if (res.data.result == "success") {
            alert("이메일 등록 성공");
            setEmail("");
            setIsEmail(false);
          }
        });
    } else {
      alert("이메일을 형식에 맞게 입력하여주세요.");
      return false;
    }
  };
  useEffect(() => {}, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>INVITE USER</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Form Elements" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">이메일 입력</CardTitle>
                  <p className="card-title-desc">초대 할 유저의 이메일 주소를 입력하세요.</p>
                  <p>SNS에 등록된 이메일을 입력하여주세요.</p>
                  <Row className="mb-3">
                    <label htmlFor="example-email-input" className="col-md-2 col-form-label">
                      Email
                    </label>
                    <div className="col-md-8">
                      <input className="form-control" type="email" value={email || ""} onChange={changeEmail} />
                      {email.length > 0 && <span className={`message ${isEmail ? "success" : "error"}`}>{emailMessage}</span>}
                    </div>
                    <div className="col-md-2">
                      <Button onClick={inviteEmail}>추가</Button>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default InviteUser;
