import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Button, Card, CardBody, Col, Form, Input, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { setCookie, getCookie, removeCookie } from "../../common/utils/Cookie";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "rc-pagination/assets/index.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const QnaBoardDetail = () => {
  const location = useLocation();
  const questionCode = location.state.questionCode;
  const userData = location.state.userData;
  // const pageInfo = location.state.currentPage;
  const [getQuestion, setGetQuestion] = useState([]);

  const [textcount, settextcount] = useState(0);
  const [titlecount, setTitletextcount] = useState(0);
  const [textareabadgeTitle, settextareabadgeTitle] = useState(0);
  const [textareabadge, settextareabadge] = useState(0);
  const [titleText, setTitleText] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [editTitleText, setEditTitleText] = useState(getQuestion.TITLE);
  const [editContentText, setEditContentText] = useState(getQuestion.CONTENT);
  const [createAnswerModal, setCreateAnswerModal] = useState(false);
  const [editAnswerModal, setEditAnswerModal] = useState(false);
  const history = useHistory();
  const toggleCreatePostModal = () => {
    setCreateAnswerModal(!createAnswerModal);
  };

  const modalBtnOnClick = async () => {
    toggleCreatePostModal();
  };
  const toggleEditPostModal = () => {
    setEditAnswerModal(!editAnswerModal);
  };

  const editBtnOnClick = async () => {
    toggleEditPostModal();
  };

  function textareachange(event) {
    const count = event.target.value.length;
    setDescriptionText(event.target.value);
    if (count > 0) {
      settextareabadge(true);
    } else {
      settextareabadge(false);
    }
    settextcount(event.target.value.length);
  }

  function titletextareachange(event) {
    const count = event.target.value.length;
    setTitleText(event.target.value);
    if (count > 0) {
      settextareabadgeTitle(true);
    } else {
      settextareabadgeTitle(false);
    }
    setTitletextcount(event.target.value.length);
  }

  const titleEditChange = e => {
    setEditTitleText(e.target.value);
  };
  const getQnaBoard = async () => {
    await axios
      .post(process.env.REACT_APP_APIKEY + "/api/qna/get", {
        QNA_CODE: questionCode,
      })
      .then(async res => {
        if (res.data.data != null) {
          setGetQuestion(res.data.data[0]);
          setEditTitleText(res.data.data[0].TITLE);
          setEditContentText(res.data.data[0].CONTENT);
        }
      });
  };

  const editBoard = async () => {
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/qna/update",
        {
          TITLE: editTitleText,
          CONTENT: editContentText,
          USER_CODE: userData.USER_CODE,
          QNA_CODE: getQuestion.QNA_CODE,
        },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
      )
      .then(async res => {
        if (res.data.data != null) {
          getQnaBoard();
          toggleEditPostModal();
        }
      });
  };
  const createBoard = async () => {
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/qna/create",
        {
          USER_CODE: userData.USER_CODE,
          PARENTS_CODE: getQuestion.PARENTS_CODE,
          DEPTH: getQuestion.DEPTH + 1,
          FAMILLY_INDEX: getQuestion.FAMILLY_INDEX + 1,
          GROUP_CODE: getQuestion.USER_CODE,
          TITLE: titleText,
          CONTENT: descriptionText,
          USER_NAME: userData.USER_NAME,
        },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
      )
      .then(async res => {
        if (res.data.data != null) {
          toggleCreatePostModal();
          history.push("/qnaboard/1");
        }
      });
  };

  const deleteBtnClick = (message = null, onConfirm, onCancel) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }
    if (onCancel && typeof onCancel !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };

    return confirmAction;
  };
  // 게시물 삭제
  const deleteConfirm = () => {
    axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/qna/setActive",
        {
          USER_CODE: getQuestion.USER_CODE,
          QNA_CODE: questionCode,
        },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
      )
      .then(async res => {
        if (res.data.data != null) {
          alert("삭제되었습니다.");
          history.goBack();
        }
      });
  };

  const cancelConfirm = () => alert("취소했습니다.");
  const confirmDelete = deleteBtnClick("삭제하시겠습니까?", deleteConfirm, cancelConfirm);

  useEffect(() => {
    if (!getCookie("xToken")) {
      history.push("/");
    }
    getQnaBoard();
  }, []);
  return (
    <React.Fragment>
      {/* 글 작성 */}
      <Modal
        size="lg"
        isOpen={createAnswerModal}
        className="exampleModal"
        toggle={toggleCreatePostModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleCreatePostModal}>1:1 문의 글 작성</ModalHeader>
          <ModalBody>
            <div className="mt-3">
              <Label>제목</Label>
              <Input
                value={titleText || ""}
                maxLength="50"
                rows="3"
                onChange={e => {
                  titletextareachange(e);
                }}
                placeholder="입력해주세요."
              />
              {textareabadgeTitle ? <span className="badgecount badge bg-success"> {titlecount} / 50 </span> : null}
            </div>
            <div className="mt-3">
              <Label>문의 내용</Label>
              <Form method="post">
                <CKEditor
                  editor={ClassicEditor}
                  data=""
                  onReady={editor => {
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // console.log(data);
                    setDescriptionText(data);
                  }}
                  config={{
                    // 여기에 config 입력
                    toolbar: ["heading", "|", "bold", "italic", "link", "bulletedList"],
                    placeholder: "글을 입력해보세요!",
                  }}
                />
              </Form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={createBoard}>
              글쓰기
            </Button>
            <Button type="button" color="secondary" onClick={toggleCreatePostModal}>
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/* 글 수정 */}
      <Modal
        size="lg"
        isOpen={editAnswerModal}
        className="exampleModal"
        toggle={toggleEditPostModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleEditPostModal}>1:1 문의 글 수정</ModalHeader>
          <ModalBody>
            <div className="mt-3">
              <Label>제목</Label>
              <Input value={editTitleText || ""} maxLength="50" rows="3" onChange={titleEditChange} placeholder="입력해주세요." />
            </div>
            <div className="mt-3">
              <Label>문의 내용</Label>
              {/* <Input type="textarea" onChange={contentEditChange} value={editContentText || ""} maxLength="5000" rows="3" placeholder="입력해주세요." /> */}
              <Form method="post">
                <CKEditor
                  editor={ClassicEditor}
                  data={getQuestion.CONTENT}
                  onReady={editor => {
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditContentText(data);
                  }}
                  config={{
                    // 여기에 config 입력
                    toolbar: ["heading", "|", "bold", "italic", "link", "bulletedList"],
                  }}
                />
              </Form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={editBoard}>
              수정
            </Button>
            <Button type="button" color="secondary" onClick={toggleEditPostModal}>
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <div className="page-content">
        <MetaTags>
          <title>Q&A Details | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Blog" breadcrumbItem="Blog Details" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div>
                          <div className="text-center">
                            <h4>{getQuestion.TITLE}</h4>
                            <p className="text-muted mb-4">
                              <i className="mdi mdi-calendar me-1"></i> {getQuestion.C_TIME}
                            </p>
                          </div>

                          <hr />

                          <div className="mt-4">
                            <div className="text-muted font-size-14">
                              {getQuestion.QNA_CODE == getQuestion.PARENTS_CODE ? <h5>질문 내용: </h5> : <h5>답변 내용: </h5>}
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: getQuestion.CONTENT }}></div>
                            {/* <div>{getQuestion.CONTENT}</div> */}
                            <hr />
                            {/* 답변 작성 Btn */}
                            <div>
                              <Button onClick={modalBtnOnClick}>답변 작성</Button>
                              <Button onClick={editBtnOnClick}>글 수정</Button>
                              <Button onClick={confirmDelete}>글 삭제</Button>
                              <Button
                                onClick={() => {
                                  history.goBack();
                                }}
                              >
                                목록
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default QnaBoardDetail;
