import React, { useEffect, useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import PropTypes from "prop-types";
import moment from "moment";
import axios from "axios";

function ChatList(props) {
  const dateTime = props.dateTime;
  const projectCode = props.projectCode;

  const [commentLists, setCommentLists] = useState([]);

  const getCommentDate = async () => {
    await axios
      .post(process.env.REACT_APP_APIKEY + "/api/comment/projectComment", {
        PROJECT_CODE: projectCode,
        C_TIME: dateTime,
      })
      .then(res => {
        if (res.data.data != null) {
          console.log(res.data.data);
          setCommentLists(res.data.data);
        }
      });
  };

  useEffect(() => {
    getCommentDate();
  }, []);

  return (
    // 반복되는 조건. dateTime 이랑 commentLists 배열의 C_TIME 이랑 같을때만 나와야함.
    <>
      {commentLists &&
        commentLists
          .filter(data => moment(data.C_TIME).format("YYYY-MM-DD") == dateTime)
          .map(data => (
            <li key={data.COMMENT_CODE} className={data.USER_CODE === "UR00000001" ? "right" : ""}>
              <div className="conversation-list">
                <div className="ctext-wrap">
                  <div className="conversation-name">{data.USER_CODE === "UR00000001" ? "" : data.USER_CODE}</div>
                  <p>{data.COMMENT_TEXT}</p>
                  <p className="chat-time mb-0">
                    <i className="bx bx-time-five align-middle me-1" />
                    {moment(data.C_TIME).format("DD-MM-YY hh:mm")}
                  </p>
                </div>
              </div>
            </li>
          ))}
    </>
  );
}
ChatList.propTypes = {
  dateTime: PropTypes.string,
  projectCode: PropTypes.string,
};

export default ChatList;
