import React, { useEffect, useState, useLayoutEffect } from "react";
import MetaTags from "react-meta-tags";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, Card } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import axios from "axios";
import { useQuery } from "react-query";
import { getCookie, removeCookie } from "common/utils/Cookie";
import { ReactQueryDevtools } from "react-query/devtools";
import { userAPI } from "common/api/user";
// 컴포넌트
import BaseInfo from "./BaseInfo";
import UserInfo from "./UserInfo";
import InfoSearch from "./InfoSearch";
import MyProjectList from "./MyProjectList";
import MyTemplate from "./MyTemplate";
import MyBlock from "./MyBlock";

function MyPageIndex() {
  const location = useLocation();

  const [companyList, setCompanyList] = useState([]);
  const [martListCount, setMartListCount] = useState("");
  const [templateInfo, setTemplateInfo] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [templateCount, setTemplateCount] = useState([]);
  const [templatePublic, setTemplatePublic] = useState("L");

  const [blockInfo, setBlockInfo] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const [blockCount, setBlockCount] = useState([]);
  const [blockPublic, setBlockPublic] = useState("L");

  const [projectList, setProjectList] = useState([]);
  const [projectInfo, setProjectInfo] = useState([]);
  const [projectCount, setProject] = useState([]);
  const history = useHistory();

  const userCode = getCookie("userCode") || location.state.userCode;
  const { data: userData } = useQuery(["users", userCode], () => userAPI(userCode), {
    enabled: !!userCode,
  });
  let myToken = getCookie("xToken");

  const getTemplateInfo = async () => {
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/template/listByUser",
        {
          USER_CODE: userCode,
          TEMPLATE_TYPE: "T",
          TEMPLATE_PUBLIC: templatePublic,
          PAGE: 1,
          ROW_COUNT: 5,
        },
        {
          headers: {
            Authorization: myToken,
            "Content-Type": "application/json;charset=UTF-8",
          },
          responseType: "json",
        },
      )
      .then(async res => {
        if (res.data.data != null) {
          setTemplateInfo(res.data.data.rows);
        } else {
          setTemplateInfo([]);
        }
      });
  };

  const listTemplate = async () => {
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/template/listByUser",
        {
          USER_CODE: userCode,
          TEMPLATE_TYPE: "T",
          TEMPLATE_PUBLIC: templatePublic,
        },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
          responseType: "json",
        },
      )
      .then(async res => {
        if (res.data.data != null) {
          setTemplateList(res.data.data.rows);
          setTemplateCount(res.data.data.count);
        } else {
          setTemplateList(res.data.data);
          setTemplateCount("0");
        }
      });
  };

  const getBlockInfo = async () => {
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/template/listByUser",
        {
          USER_CODE: userCode,
          TEMPLATE_TYPE: "B",
          TEMPLATE_PUBLIC: blockPublic,
          PAGE: 1,
          ROW_COUNT: 5,
        },
        {
          headers: {
            Authorization: myToken,
            "Content-Type": "application/json;charset=UTF-8",
          },
          responseType: "json",
        },
      )
      .then(async res => {
        if (res.data.data != null) {
          setBlockInfo(res.data.data.rows);
        } else {
          setBlockInfo(res.data.data);
        }
      });
  };

  const listBlock = async () => {
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/template/listByUser",
        {
          USER_CODE: userCode,
          TEMPLATE_TYPE: "B",
          TEMPLATE_PUBLIC: blockPublic,
        },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
          responseType: "json",
        },
      )
      .then(async res => {
        if (res.data.data != null) {
          setBlockList(res.data.data.rows);
          setBlockCount(res.data.data.count);
        } else {
          setBlockList(res.data.data);
          setBlockCount("0");
        }
      });
  };

  const listCompany = async () => {
    await axios
      .post(process.env.REACT_APP_APIKEY + "/api/company/listCompany", {
        headers: {
          Authorization: getCookie("xToken"),
          "Content-Type": "application/json;charset=UTF-8",
        },
        responseType: "json",
      })
      .then(async res => {
        if (res.data.data != null) {
          setCompanyList(res.data.data);
        }
      });
  };
  const listMartCount = async () => {
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/company/listMartCount",
        {
          USER_CODE: userCode,
        },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
          responseType: "json",
        },
      )
      .then(async res => {
        if (res.data.data != null) {
          setMartListCount(res.data.data);
        }
      });
  };
  const listProject = async () => {
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/project/list",
        {
          userCode: userCode,
        },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
          responseType: "json",
        },
      )
      .then(async res => {
        if (res.data.data != null) {
          setProjectList(res.data.data.rows);
          setProject(res.data.data.count);
        }
      });
  };
  const getProjectInfo = async () => {
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/project/list",
        {
          // USER_CODE: '',
          PAGE: 1,
          ROW_COUNT: 5,
        },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
          responseType: "json",
        },
      )
      .then(async res => {
        if (res.data.data != null) {
          setProjectInfo(res.data.data.rows);
        }
      });
  };

  // if (getCookie("snsType") == "kakao") {
  //   window.location.replace("/mypage");
  // }
  useEffect(() => {
    if (!getCookie("xToken") && !userCode) {
      removeCookie("xToken");
      history.push("/");
    }
  }, []);

  useEffect(() => {
    getTemplateInfo();
    listMartCount();
    getBlockInfo();
    listTemplate();
    listBlock();
    listCompany();
    listProject();
    getProjectInfo();
  }, []);

  useLayoutEffect(() => {
    console.log("userData =>", userData);
  }, []);
  document.body.style = "background-color:#FFFFFF";
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>SHUKET | MYPAGE</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="UI Elements" breadcrumbItem="Alerts" />

          <Row>
            <Col lg={6}>
              <Card>
                {" "}
                <BaseInfo userInfo={userData} companyList={martListCount} projectList={projectList} />{" "}
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                {" "}
                <UserInfo userInfo={userData} />{" "}
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <InfoSearch userData={userData} />
              </Card>
            </Col>

            <Col lg={12}>
              <Card>
                <MyProjectList
                  userData={userData}
                  projectInfo={projectInfo}
                  companyList={companyList}
                  projectList={projectList}
                  listProject={listProject}
                  projectCount={projectCount}
                  getProjectInfo={getProjectInfo}
                  templatePublic={templatePublic}
                />
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Card>
                <MyTemplate
                  templateInfo={templateInfo}
                  templateList={templateList}
                  templateCount={templateCount}
                  userData={userData}
                  getTemplateInfo={getTemplateInfo}
                  listTemplate={listTemplate}
                  setTemplatePublic={setTemplatePublic}
                />
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <MyBlock
                  blockInfo={blockInfo}
                  blockList={blockList}
                  blockCount={blockCount}
                  userData={userData}
                  setBlockPublic={setBlockPublic}
                  getBlockInfo={getBlockInfo}
                  listBlock={listBlock}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </React.Fragment>
  );
}

export default MyPageIndex;
