import React, { useState, useRef } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
import axios from "axios";
import { saveAs } from "file-saver";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

function CommentItem({ message, userInfo, commentLists, setCommentLists, removeComment }) {
  const focusRef = useRef(null);
  const [editable, setEditable] = useState(false);
  const [newText, setNewText] = useState(message.COMMENT_TEXT);
  const [imgOpen, setImgOpen] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0);
  const [isGallery, setisGallery] = useState(false);

  const editSubmit = COMMENT_CODE => {
    axios
      .post(process.env.REACT_APP_APIKEY + `/api/comment/commentUpdate`, {
        COMMENT_CODE: COMMENT_CODE,
        USER_CODE: userInfo.USER_CODE,
        COMMENT_TEXT: newText,
      })
      .then(res => {
        if (res.data.result === "success") {
          console.log(res);
          return true;
        } else {
          console.log("수정실패");
          console.log(res);
          return false;
        }
      });
  };
  const updateCommentBtn = e => {
    const nextUpdateText = commentLists.map(item => ({
      ...item,
      COMMENT_TEXT: item.COMMENT_CODE === message.COMMENT_CODE ? newText : item.COMMENT_TEXT,
    }));
    setCommentLists(nextUpdateText);
    setEditable(!editable);
  };

  const updateKeyPress = e => {
    if (e.keyCode === 13 && e.shiftKey) {
      setNewText(newText);
    } else if (e.key === "Enter") {
      const nextUpdateText = commentLists.map(item => ({
        ...item,
        COMMENT_TEXT: item.COMMENT_CODE === message.COMMENT_CODE ? newText : item.COMMENT_TEXT,
      }));
      setCommentLists(nextUpdateText);
      setEditable(!editable);
    }
  };

  return (
    <li className={message?.USER_CODE === userInfo?.USER_CODE ? "right" : ""}>
      <div className="conversation-list">
        {message?.USER_CODE === userInfo?.USER_CODE ? (
          <UncontrolledDropdown>
            <DropdownToggle className="btn nav-btn" tag="i">
              <i className="bx bx-dots-vertical-rounded" />
            </DropdownToggle>
            <DropdownMenu>
              {message.COMMENT_TEXT && (
                <>
                  {editable ? (
                    <DropdownItem
                      onClick={() => {
                        editSubmit(message.COMMENT_CODE);
                        updateCommentBtn();
                      }}
                    >
                      수정완료
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      onClick={() => {
                        setEditable(!editable);
                        // focusTextInput();
                      }}
                    >
                      수정
                    </DropdownItem>
                  )}
                </>
              )}

              {message.COMMENT_IMG && (
                <DropdownItem onClick={() => saveAs(process.env.REACT_APP_APIKEY + "/" + message.COMMENT_IMG, message.COMMENT_IMG)}>다운로드</DropdownItem>
              )}
              {message?.USER_CODE === userInfo?.USER_CODE && <DropdownItem onClick={() => removeComment(message.COMMENT_CODE)}>삭제</DropdownItem>}
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          <>
            {message.COMMENT_IMG && (
              <UncontrolledDropdown>
                <DropdownToggle className="btn nav-btn" tag="i">
                  <i className="bx bx-dots-vertical-rounded" />
                </DropdownToggle>
                <DropdownMenu>
                  {message.COMMENT_IMG && (
                    <DropdownItem onClick={() => saveAs(process.env.REACT_APP_APIKEY + "/" + message.COMMENT_IMG, message.COMMENT_IMG)}>다운로드</DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </>
        )}
        <div className="ctext-wrap">
          <div className="conversation-name">{userInfo?.USER_CODE ? null : message?.USER_NAME}</div>
          {editable ? (
            <>
              <textarea
                style={{
                  borderRadius: "5px",
                  border: "1px solid gray",
                  backgroundColor: "transparent",
                  padding: "5px",
                  overflow: "hidden",
                  resize: "none",
                  width: "250px",
                  height: "80px",
                }}
                autoFocus
                onFocus={function (e) {
                  var val = e.target.value;
                  e.target.value = "";
                  e.target.value = val;
                }}
                id={message.COMMENT_CODE}
                name="editValue"
                onKeyPress={updateKeyPress}
                onChange={e => {
                  setNewText(e.target.value);
                }}
                value={newText}
              />
            </>
          ) : (
            <>
              {/* {console.log(message.findIndex(item => item.id))} */}
              {message.COMMENT_TEXT && <div style={{ whiteSpace: "pre-line" }}>{message.COMMENT_TEXT}</div>}
              {message.COMMENT_IMG && (
                <img
                  src={process.env.REACT_APP_APIKEY + "/" + message.COMMENT_IMG}
                  onClick={() => {
                    setImgOpen(true);
                  }}
                  style={{ width: "120px", marginBottom: "5px", cursor: "pointer" }}
                />
              )}
              {imgOpen && <Lightbox mainSrc={process.env.REACT_APP_APIKEY + "/" + message.COMMENT_IMG} onCloseRequest={() => setImgOpen(false)} />}
            </>
          )}

          <p className="chat-time mb-0">
            <i className="bx bx-time-five align-middle me-1" />
            {moment(message.M_TIME).format(`YY-MM-DD a hh:mm`)}
          </p>
        </div>
      </div>
    </li>
  );
}

CommentItem.propTypes = {
  message: PropTypes.object,
  commentLists: PropTypes.array,
  userInfo: PropTypes.object,
  setCommentLists: PropTypes.func,
  removeComment: PropTypes.func,
};

export default CommentItem;
