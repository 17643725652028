import axios from "axios";
import { getCookie } from "common/utils/Cookie";

let myUserCode = getCookie("userCode");

// USER_CODE 직접 받아와서 데이터 받기
export function userAPI(userCode) {
  return axios.post(process.env.REACT_APP_APIKEY + `/api/users/get`, { USER_CODE: userCode }).then(res => res.data.data);
}
// 유저 권한
export function userRolesAPI({ USER_CODE, COMPANY_CODE }) {
  return axios
    .post(
      process.env.REACT_APP_APIKEY + "/api/users/userRoles",
      { USER_CODE, COMPANY_CODE },
      {
        headers: {
          Authorization: getCookie("xToken"),
          "Content-Type": "application/json;charset=UTF-8",
        },
      },
    )
    .then(res => res.data.data);
}

// 유저 코드로 유저 정보 가져오기
export function getUserAPI(userCode) {
  return axios.post(process.env.REACT_APP_APIKEY + `/api/users/get`, { USER_CODE: myUserCode }).then(res => res.data.data);
}

// 삭제된 유저 리스트
export function delteUserListAPI({ USER_CODE, COMPANY_CODE }) {
  return axios.post(process.env.REACT_APP_APIKEY + "/api/users/deleteuserlist", { USER_CODE, COMPANY_CODE }).then(res => res.data.data);
}

// 블랙리스트
export function blockListAPI({ USER_CODE, COMPANY_CODE, STATUS }) {
  return axios.post(process.env.REACT_APP_APIKEY + "/api/users/userRefusRist", { USER_CODE, COMPANY_CODE, STATUS }).then(res => res.data.data);
}
