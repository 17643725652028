import React, { useState, useEffect } from "react";
import ChatList from "./ChatList";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import PropTypes from "prop-types";
function HistoryComment(props) {
  const [commentDatesTest, setCommentDates] = useState([]);
  const projectCode = props.companyData;
  const commentDates = props.commentDates;

  const scrollToBottom = () => {
    if (commentDates) {
      commentDates.scrollTop = commentDates.scrollHeight + 800;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (!commentDates) scrollToBottom();
  }, []);
  return (
    <>
      <div>
        <div className="chat-conversation p-3">
          <ul className="list-unstyled">
            <PerfectScrollbar style={{ height: "470px" }} containerRef={ref => setCommentDates(ref)}>
              {commentDates ? (
                commentDates.map(commentDate => {
                  return (
                    <li key={commentDate.DATETIME}>
                      <div className="chat-day-title">
                        <span className="title">{commentDate.DATETIME}</span>
                      </div>

                      <ul>
                        <ChatList projectCode={projectCode} dateTime={commentDate.DATETIME} />
                      </ul>
                    </li>
                  );
                })
              ) : (
                <li>연락내역이 없습니다.</li>
              )}
            </PerfectScrollbar>
          </ul>
        </div>
      </div>
    </>
  );
}
HistoryComment.propTypes = {
  companyData: PropTypes.string,
  commentDates: PropTypes.array,
};

export default HistoryComment;
