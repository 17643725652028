import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TabBusiness from "./TabBusiness";
import TabContact from "./TabContact";

import classnames from "classnames";
function InputTab(props) {
  const [activeTab, setactiveTab] = useState("");
  const DEFAULT_ACTIVE_TAB = "tabBusiness";

  const tabs = {
    tabBusiness: {
      title: "사업자 정보",
      content: <TabBusiness />,
    },
    tabContent: {
      title: "추가 정보",
      content: (
        <Row>
          <Col sm="12">
            <TabContact />
          </Col>
        </Row>
      ),
    },
  };
  const { active_tab } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!active_tab) {
      history.push(`/loginTab/${DEFAULT_ACTIVE_TAB}`);
    }
    console.log(active_tab);
  }, []);
  const toggle = tab => {
    if (active_tab !== tab) {
      setactiveTab(tab);
      history.push(`/loginTab/${tab}`);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tabs & Accordions | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container>
          <Row className="justify-content-center">
            <Breadcrumbs title="UI Elements" breadcrumbItem="Tabs & Accordions" />
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Nav pills className="navtab-bg nav-justified">
                    {Object.entries(tabs).map(tab => (
                      <NavItem key={tab[0]}>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: active_tab === tab[0] ? "active" : "",
                          })}
                        >
                          {tab[1].title}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>

                  <TabContent activeTab={active_tab}>
                    {Object.entries(tabs).map(tab => (
                      <TabPane key={tab[0]} tabId={tab[0]}>
                        {tab[1].content}
                      </TabPane>
                    ))}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
InputTab.propTypes = {
  location: PropTypes.any,
  onChange: PropTypes.func,
};

export default InputTab;
