import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Input,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import DaumPostcode from "react-daum-postcode";
import { API_URL } from "../../common/url";
import { ProgressBar } from "react-bootstrap";
import InputMask from "react-input-mask";
import { useHistory, useLocation } from "react-router-dom";
const TabPrivacy = props => {
  const [privacy, setPrivacy] = useState("");
  const [listOption, setListOption] = useState([]);
  const [Selected, setSelected] = useState("33");
  const handleSelect = e => {
    setSelected(e.target.value);
    console.log(Selected);
  };
  const listOptionArr = async () => {
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/terms/listSelectBoxJson",
        {
          title: "개인정보 처리방침",
        },
        {
          headers: {
            contentType: "application/json",
            userAgent: "DEVICE-AGENT",
          },
        },
      )
      .then(response => {
        // console.log(response.data.data);
        setListOption(response.data.data);
        console.log(listOption);
      });
  };

  const getPrivacy = async () => {
    await axios
      .post(process.env.REACT_APP_APIKEY + "/terms/getPrivacy", {
        headers: {
          contentType: "application/json",
          userAgent: "DEVICE-AGENT",
        },
      })
      .then(response => {
        setPrivacy(response.data.data);
      });
  };
  useEffect(() => {
    listOptionArr();
  }, []);
  useEffect(() => {
    getPrivacy();
  }, [Selected]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Form Layouts | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">개인정보 처리방침</CardTitle>
                  <div dangerouslySetInnerHTML={{ __html: privacy }}></div>
                </CardBody>
                <select onChange={handleSelect} value={Selected}>
                  {listOption &&
                    listOption.map(item => (
                      <option value={item.TEXT_CODE} key={item.M_TIME}>
                        {item.M_TIME}
                      </option>
                    ))}
                </select>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
TabPrivacy.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default TabPrivacy;
