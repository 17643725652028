import React, { useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { setCookie, getCookie, removeCookie } from "../../common/utils/Cookie";
import { Spinner } from "reactstrap";

let code = new URL(window.location.href).searchParams.get("code");
const kakaoLogin = props => {
  const history = useHistory();

  // 인가코드
  const codeFetch = async () => {
    await axios.get(process.env.REACT_APP_APIKEY + `/api/auth/snsToken?code=${code}`).then(async res => {
      await axios
        .post(process.env.REACT_APP_APIKEY + `/api/auth/myKakao`, {
          token: res.data,
        })
        .then(user => {
          let xToken = user.data.xToken;
          let snsType = "kakao";
          if (user.data.result === "wait") {
            history.push("/userSignUp");
          } else if (user.data.result === "success") {
            setCookie("xToken", xToken, {
              path: "/",
              maxAge: 24 * 60 * 60,
            });
            setCookie("snsType", snsType, {
              path: "/",
              maxAge: 24 * 60 * 60,
            });
            setCookie("userCode", JSON.stringify(user.data.data[0].USER_CODE), {
              path: "/",
              maxAge: 24 * 60 * 60,
            });
            const userCode = user.data.data[0].USER_CODE;
            // history.push({
            //   pathname: "/mypage",
            //   state: { userEmail: user.data.email, userCode: userCode },
            // });
            window.location.replace("/mypage");
          } else {
            let snsData = {
              userEmail: user.data.email,
              snsType: "KAKAO",
            };
            history.push({
              pathname: "/userSelect",
              state: { userData: snsData },
            });
          }
        });
    });
  };

  useEffect(async () => {
    if (code) {
      codeFetch();
    }
  }, [code]);

  const containerBox = {
    position: "relative",
    width: "100%",
    height: "100%",
  };
  const layoutBox = {
    width: "300px",
    height: "300px",
    margin: "0 auto",
    transform: "translateY(50%)",
  };
  const textBox = {
    textAlign: "center",
  };
  return (
    <div style={containerBox}>
      <div style={layoutBox}>
        <div style={textBox}>
          <Spinner className="ms-2" color="warning" style={{ width: "100px", height: "100px" }} />
          <p style={{ marginTop: "40px", fontSize: "16px" }}>카카오 로그인 중입니다 ... </p>
        </div>
      </div>
    </div>
  );
};

export default kakaoLogin;
