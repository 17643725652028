import React, { useState, useEffect, useLayoutEffect, useCallback } from "react";
import MetaTags from "react-meta-tags";
import axios from "axios";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useQuery, useMutation, queryClient, useQueryClient } from "react-query";

import { Container, Row, Col, Button, Card, CardBody, Input, Table, CardTitle, CardSubtitle, Spinner } from "reactstrap";
import { getUserAPI, delteUserListAPI, deleteupdateAPI } from "common/api/user";
import { getCookie } from "common/utils/Cookie";

function DeleteUser() {
  const [selected, setSelected] = useState("");
  const queryClient = useQueryClient();
  // 유저 정보 가져오기
  const { data: userInfo, isLoading } = useQuery("user", getUserAPI);
  const userCode = userInfo?.USER_CODE;
  const companyCode = userInfo?.COMPANY_CODE;
  // 삭제 유저 리스트
  const { data: dataLists } = useQuery(["users", userCode, companyCode], () => delteUserListAPI({ USER_CODE: userCode, COMPANY_CODE: companyCode }), {
    enabled: !!userCode,
  });
  console.log("리스트", dataLists);

  // 유저 상태 수정
  const updateData = useMutation({
    mutationFn: USER_CODE =>
      axios.post(
        process.env.REACT_APP_APIKEY + `/api/users/deleteupdate`,
        { USER_CODE: USER_CODE, COMPANY_ACTIVE: selected, ACTIVE: selected },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        console.log("USER_CODE ------ ", USER_CODE),
      ),
    onSuccess: () => {
      // 요청이 성공한 경우
      console.log("onSuccess");
      queryClient.invalidateQueries("users");
    },
    onError: error => {
      alert("유저 수정에 실패하였습니다.");
    },
  });
  // 유저 상태 수정 버튼
  const updateBtn = USER_CODE => {
    updateData.mutate(USER_CODE); // 데이터 저장
  };

  let optionGroup = [
    { label: "삭제", value: "N" },
    { label: "복구", value: "Y" },
  ];

  var inquiries = optionGroup.filter(data => {
    if (selected === "N" && data.label.toLowerCase() === "N") {
      console.log("1", data);
      return data;
    } else if (selected === "Y" && data.label.toLowerCase() === "Y") {
      console.log("2", data);
      return data;
    }
  });

  const changeSelectOptionHandler = e => {
    setSelected(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>SHUKET | Delete User List</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="UI Elements" breadcrumbItem="삭제된 사용자 리스트" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle>삭제된 사용자</CardTitle>
                  <CardSubtitle className="mb-3">삭제된 유저 리스트의 정보를 변경할 수 있습니다.</CardSubtitle>

                  <div>
                    <Table className="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>이름</th>
                          <th>이메일</th>
                          <th>전화번호</th>
                          <th>직함</th>
                          <th>삭제여부</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLists ? (
                          dataLists.map((deleteList, index) => {
                            return (
                              <tr key={deleteList.USER_CODE}>
                                <th scope="row">{index + 1}</th>
                                <td>{deleteList.USER_NAME}</td>
                                <td>{deleteList.USER_EMAIL}</td>
                                <td>{deleteList.PHONE_NUM}</td>
                                <td>{deleteList.USER_POSITION}</td>
                                <td>
                                  <Input
                                    className="form-control"
                                    type="select"
                                    name={deleteList.USER_CODE}
                                    id={deleteList.USER_CODE}
                                    defaultValue={deleteList.ACTIVE}
                                    value={inquiries.value}
                                    onChange={e => {
                                      changeSelectOptionHandler(e);
                                    }}
                                  >
                                    <option name="N" value="N">
                                      삭제
                                    </option>
                                    <option name="Y" value="Y">
                                      복구
                                    </option>
                                  </Input>
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      updateBtn(deleteList.USER_CODE);
                                      console.log(deleteList.USER_CODE);
                                    }}
                                  >
                                    수정
                                  </Button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <th>삭제된 사용자가 없습니다.</th>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {isLoading ? <Spinner className="ms-2" color="warning" style={{ width: "50px", height: "50px", margin: "40px 50%" }} /> : ""}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default DeleteUser;
