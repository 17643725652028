// import React, { useEffect } from "react";
// import { Link, useLocation, useHistory } from "react-router-dom";

// function TemplateUpload() {
//   return <div>Template Upload Page</div>;
// }

// export default TemplateUpload;

import React, { useState, useEffect, useRef } from "react";
import MetaTags from "react-meta-tags";
import axios from "axios";
import Select from "react-select";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Row, CardTitle, Container, Label, Input, Button, Table } from "reactstrap";
import { ProgressBar } from "react-bootstrap";
import { useQuery } from "react-query";
import moment from "moment";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getCookie } from "common/utils/Cookie";
import "rc-pagination/assets/index.css";
import Pagination from "rc-pagination";
import { getUserAPI, userAPI, delteUserListAPI, deleteupdateAPI } from "../../common/api/user";
function TemplateUpload() {
  const [file, setFile] = useState(null);
  const [isFile, setIsFile] = useState(false);
  const [progress, setProgress] = useState([]);
  // const [userData, setUserData] = useState([]);
  const [pageSize, setPageSize] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("TS00000001"); //
  const [fileList, setFileList] = useState([]);
  const [fileCount, setFileCount] = useState([]);
  const [check, setCheck] = useState("INDESIGN");
  const [isCheck, setIsCheck] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  // const [indesign, setIndesign] = useState("");
  // const [corel, setCorel] = useState("");
  // const [uploadModal, setUploadModal] = useState([]);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = fileList != null ? fileList.slice(indexOfFirstPost, indexOfLastPost) : [];
  let optionGroup = [
    { label: "2절 가로", value: "TS00000001" },
    { label: "2절 세로", value: "TS00000002" },
    { label: "4절 가로", value: "TS00000003" },
    { label: "4절 세로", value: "TS00000004" },
    { label: "8절 가로", value: "TS00000005" },
    { label: "8절 세로", value: "TS00000006" },
  ];
  const groupOnChange = value => {
    setSelectedGroup(value);
  };
  const userCode = getCookie("userCode") || location.state.userCode;
  const { data: userData } = useQuery(["users", userCode], () => userAPI(userCode));
  const listUploadFile = async () => {
    await axios
      .post(
        process.env.REACT_APP_APIKEY + "/api/upload/list",
        {
          USER_CODE: userData?.USER_CODE,
          // PAGE: "1",
        },
        {
          headers: {
            Authorization: getCookie("xToken"),
            "Content-Type": "application/json;charset=UTF-8",
          },
          responseType: "json",
        },
      )
      .then(async res => {
        if (res.data.data != null) {
          setFileList(res.data.data.rows);
          setFileCount(res.data.data.count);
          setProgress([]);
        }
      });
  };
  // console.log(userData);
  const confirmDelete = e => {
    let fileCode = e.target.getAttribute("fc");
    let filePath = e.target.getAttribute("path");

    if (window.confirm("정말 삭제합니까?")) {
      axios
        .post(process.env.REACT_APP_APIKEY + "/api/upload/setActive", {
          USER_CODE: userData?.USER_CODE,
          FILE_CODE: fileCode,
          FILE_PATH: filePath,
        })
        .then(async res => {
          if (res.data.data != null) {
            alert("삭제되었습니다.");
            listUploadFile();
          }
        });
    } else {
      alert("취소합니다.");
    }
  };
  const handleOnChange = async e => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setIsFile(true);
  };
  const fileInput = useRef();
  const onClearAttachment = () => {
    fileInput.current.value = "";
  };
  const uploadBtn = async () => {
    if (!isFile) {
      alert("파일을 업로드 해주세요.");
      return false;
    }
    const formData = new FormData();
    formData.append("uploadfile", file);
    formData.append("USER_CODE", userData?.USER_CODE);
    formData.append("USER_NAME", userData?.USER_NAME);
    formData.append("unique", true);
    formData.append("FILE_TYPE", check);
    if (userData?.COMPANY_CODE) {
      formData.append("COMPANY_CODE", userData?.COMPANY_CODE);
    }
    if (check == "COREL") {
      formData.append("PAGESIZE_CODE", selectedGroup);
    }
    // console.log(formData);
    const response = await axios.post(process.env.REACT_APP_APIKEY + `/upload/uploadTemplate`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: data => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    });
    const data = response;
    // console.log(data);
    if (data.data.result == "success") {
      setFile(null);
      setIsFile(false);
      onClearAttachment();
      listUploadFile();
      alert("업로드가 완료되었습니다.");
    } else {
      setFile(null);
      setIsFile(false);
      onClearAttachment();
      setProgress([]);
      alert("에러가 발생하였습니다. 다시 시도해주세요.");
    }
  };
  const PageSize = () => {
    return <p>Test!!!!!!!!!!!!!</p>;
  };

  useEffect(() => {
    listUploadFile();
  }, [userData]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Template Upload</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Template Upload" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">템플릿 업로드</CardTitle>
                  <p className="card-title-desc">템플릿을 업로드 해주세요.</p>
                  <div className="form-check mb-3 box-layout">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios1"
                      value="INDESIGN"
                      onChange={e => {
                        setCheck(e.target.value);
                        console.log(e.target.value);
                        setSelectedGroup("");
                      }}
                      defaultChecked
                    />
                    <label className="form-check-label label-box" htmlFor="exampleRadios1">
                      INDESIGN
                    </label>
                  </div>

                  <div className="form-check mb-3 box-layout">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios2"
                      value="COREL"
                      onChange={e => {
                        setCheck(e.target.value);
                        console.log(e.target.value);
                      }}
                    />
                    <label className="form-check-labe2 label-box" htmlFor="exampleRadios2">
                      COREL
                    </label>
                  </div>
                  {check == "COREL" ? (
                    <Col sm={2}>
                      <Select
                        value={optionGroup.find(op => {
                          // choice state에 따라 디폴트 option 세팅
                          return op.value === selectedGroup;
                        })}
                        onChange={selectedGroup => {
                          groupOnChange(selectedGroup.value);
                        }}
                        classNamePrefix="select2-selection"
                        options={optionGroup}
                      />
                    </Col>
                  ) : (
                    <Col></Col>
                  )}

                  {/* // <Col sm={2}>
                  //   <Select
                  //     value={optionGroup.find(op => {
                  //       // choice state에 따라 디폴트 option 세팅
                  //       return op.value === selectedGroup;
                  //     })}
                  //     onChange={selectedGroup => {
                  //       groupOnChange(selectedGroup.value);
                  //     }}
                  //     classNamePrefix="select2-selection"
                  //     options={optionGroup}
                  //   />
                  // </Col> */}
                  {/* <label htmlFor="INDESIGN">
                    <div className="form-check mb-3 box-layout">
                      <input className="form-check-input" id="INDESIGN" value="INDESIGN" name="INDESIGN" type="radio" onChange={radioChange1} />
                      INDESIGN
                    </div>
                  </label>

                  <label htmlFor="COREL">
                    <div className="form-check mb-3 box-layout">
                      <input className="form-check-input" id="COREL" value="COREL" name="COREL" type="radio" onChange={radioChange2} />
                      COREL
                    </div>
                  </label> */}

                  <input type="file" name="uploadfile" ref={fileInput} accept=".zip,.rar" onChange={handleOnChange} />
                  <Button onClick={uploadBtn}>업로드</Button>
                  {progress && <ProgressBar now={progress} label={`${progress}%`} />}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">템플릿 리스트</CardTitle>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="table-light">
                        <tr>
                          <th>#</th>
                          <th>파일이름</th>
                          <th>폴더위치</th>
                          <th>생성자</th>
                          <th>생성날자</th>
                          <th>삭제</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fileList ? (
                          currentPosts.map(data => {
                            console.log(currentPosts);
                            return (
                              <tr key={data.FILE_CODE}>
                                <td scope="row">{data.row_num}</td>
                                <td>{data.FILE_NAME}</td>
                                <td>{data.TEMPLATE_FILE_PATH}</td>
                                <td>{data.USER_NAME}</td>
                                <td>{moment(data.C_TIME).format("YYYY-MM-DD")}</td>
                                <td>
                                  <Button onClick={confirmDelete} fc={data.FILE_CODE} path={data.TEMPLATE_FILE_PATH}>
                                    삭제
                                  </Button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  {fileCount && fileCount ? (
                    <Pagination
                      className="ant-pagination d-flex justify-content-center"
                      total={fileCount}
                      current={currentPage}
                      pageSize={postsPerPage}
                      onChange={page => setCurrentPage(page)}
                    />
                  ) : (
                    <div></div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default TemplateUpload;
