import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Table, Button } from "reactstrap";
import Breadcrumbs from "../Common/Breadcrumb";
import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { getUserAPI, userAPI } from "common/api/user";

function Comment() {
  const history = useHistory();
  const [projectLists, setProjectLists] = useState([]);

  const { data: userInfo } = useQuery("user", getUserAPI);

  useEffect(async () => {
    await axios.post(process.env.REACT_APP_APIKEY + "/api/project/list").then(res => {
      setProjectLists(res?.data?.data?.rows);
    });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>SHUKET | Comment</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="UI Elements" breadcrumbItem="프로젝트 댓글 " />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle>프로젝트</CardTitle>
                  <CardSubtitle className="mb-3">진행된 프로젝트 목록입니다.</CardSubtitle>
                  <div>
                    <Table className="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>회사</th>
                          <th>프로젝트명</th>
                          <th>프로젝트 설명</th>
                          <th>마트</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectLists ? (
                          projectLists.map((projectList, index) => {
                            return (
                              <tr key={projectList.PROJECT_CODE}>
                                <td scope="row">{index + 1}</td>
                                <td>{projectList.AGENCY_NAME}</td>
                                <td>{projectList.PROJECT_NAME}</td>
                                <td>{projectList.PROJECT_DESCRIPTION}</td>
                                <td>{moment(projectList.M_TIME).format("YYYY-MM-DD")}</td>
                                <td>
                                  <Button
                                    size="sm"
                                    type="button"
                                    color="success"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("클릭 프젝", projectList.PROJECT_CODE);
                                      history.push({
                                        pathname: `/markerinfo/${projectList.PROJECT_CODE}`,
                                        state: { projectInfo: projectList, userInfo: userInfo },
                                      });
                                    }}
                                  >
                                    바로가기
                                  </Button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td>프로젝트 정보가 없습니다.</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Comment;
