import React from 'react';
import { useHistory } from "react-router-dom";

function kakaoUser(code) {
  const history = useHistory();

  return function (dispatch, getState) {
    axios({
      method:'GET',
      url: `http://localhost:3000/kakao/login?code=${code}`,
    })
    .then((res) => {
      console.log(res);

      const ACCESS_TOKEN = res.data.accessToken;
      console.log('ACC_TOKEN', ACCESS_TOKEN);
    })
    .catch((err)=> {
      console.log('소셜로그인 에러', err);
    })
  }
}

export default kakaoUser;
