import React from "react";
import PropTypes from "prop-types";
import { CardBody, Card, Row, Col } from "reactstrap";
import profile from "assets/images/shuketlogo.png";
import profile1 from "assets/images/shuketlogo.png";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
function BaseInfo(props) {
  const userInfo = props.userInfo;
  const projectList = props.projectList;
  const companyList = props.companyList;
  React.useEffect(() => {}, []);
  return (
    <>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row style={{ height: "115px" }}>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">{props.t("Shuket OPS - My Page")}</h5>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              {/* <img src={profile} alt="" className="img-fluid" /> */}
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col className="col-sm-2">
              <div className="avatar-md profile-user-wid ">
                <img src={profile1} alt="" className="img-thumbnail rounded-circle" />
              </div>
              <h5 className="font-size-15 text-truncate">{userInfo?.USER_NAME}</h5>
              <p className="text-muted mb-0 text-truncate">{userInfo?.USER_POSITION}</p>
            </Col>

            <Col className="col-sm-7">
              <div className="pt-4">
                <Row style={{ textAlign: "center" }}>
                  <Col xs="6">
                    <h5 className="font-size-15">{projectList ? projectList.length : 1}</h5>
                    <p className="text-muted mb-0">{props.t("Project")}</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">{companyList ? companyList : 0}</h5>
                    <p className="text-muted mb-0">{props.t("Mart")}</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
BaseInfo.propTypes = {
  userInfo: PropTypes.any,
  projectList: PropTypes.array,
  companyList: PropTypes.any,
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(BaseInfo));
