import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import PropTypes from "prop-types";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TabService from "./TabService";
import TabPrivacy from "./TabPrivacy";

import classnames from "classnames";
function InputTab(props) {
  const [activeTab, setactiveTab] = useState("");
  const DEFAULT_ACTIVE_TAB = "tabService";

  const tabs = {
    tabService: {
      title: "서비스 이용약관",
      content: <TabService />,
    },
    tabPrivacy: {
      title: "개인정보 처리방침",
      content: <TabPrivacy />,
    },
  };
  const { active_tab } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!active_tab) {
      history.push(`/termsTab/${DEFAULT_ACTIVE_TAB}`);
    }
    console.log(active_tab);
  }, []);
  const toggle = tab => {
    if (active_tab !== tab) {
      setactiveTab(tab);
      history.push(`/termsTab/${tab}`);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Tabs & Accordions | Skote - React Admin & Dashboard Template
          </title>
        </MetaTags>
        <Container>
          <Row className="justify-content-center">
            <Breadcrumbs
              title="UI Elements"
              breadcrumbItem="Tabs & Accordions"
            />
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Nav pills className="navtab-bg nav-justified">
                    {Object.entries(tabs).map(tab => (
                      <NavItem key={tab[0]}>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: active_tab === tab[0] ? "active" : "",
                          })}
                          onClick={() => {
                            toggle(tab[0]);
                          }}
                          role="button"
                        >
                          {tab[1].title}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>

                  <TabContent activeTab={active_tab}>
                    {Object.entries(tabs).map(tab => (
                      <TabPane key={tab[0]} tabId={tab[0]}>
                        {tab[1].content}
                      </TabPane>
                    ))}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
InputTab.propTypes = {
  location: PropTypes.any,
  onChange: PropTypes.func,
};

export default InputTab;
