import React, { useEffect } from "react";
import MyPageIndex from "../../components/MyPage/index";
function MyPage() {
  return (
    <React.Fragment>
      <MyPageIndex />
    </React.Fragment>
  );
}

export default MyPage;
