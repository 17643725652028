import React from "react";
import MetaTags from "react-meta-tags";
// Redux
import { Link } from "react-router-dom";
import { removeCookie } from "../../common/utils/Cookie";
import { Row, Col, CardBody, Card, Container, Form, Button } from "reactstrap";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/shuket_logo.png";
import lightlogo from "../../assets/images/logo-light.svg";

const UserSignUp = () => {
  React.useEffect(() => {
    removeCookie("xToken");
  }, []);
  return (
    <React.Fragment>
      <MetaTags>
        <title>OPS - Online Publishing System</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={8} xl={7}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">현재 가입신청 완료 상태입니다.</h5>
                        <p>자세한 내용은 계정 초대자에게 문의하실 수 있습니다.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4" style={{ border: "1px solid red" }}>
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={lightlogo} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" height="24" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="mt-4 text-center">
                    <h1>가입 승인 대기중 . . . </h1>
                  </div>

                  <div className="p-2">
                    <div className="mt-4 text-center">
                      {/* <LogOutBtn /> */}
                      <Link to="/login">
                        <Button color="primary" className="w-md">
                          홈으로
                        </Button>
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserSignUp;
